import MessageBox from '@components/Common/MessageBox';
import Loading from '@components/Layout/Loading';
import { useAnalytics } from '@hooks/useAnalytics';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import React, { useState } from 'react';

interface CardDialogContentProps {
  onClose: () => void;
  onSubmit: (paymentMethodId: string) => void;
  error?: string | null;
}
interface CardDialogProps extends CardDialogContentProps {
  stripe: Stripe | null;
  open: boolean;
}

// Internal component that uses Stripe hooks
const CardDialogContent: React.FC<CardDialogContentProps> = ({
  onClose,
  onSubmit,
  error: externalError,
}) => {
  const { trackEvent } = useAnalytics();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [elementsReady, setElementsReady] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const theme = useTheme();

  const allElementsReady = Object.values(elementsReady).every((ready) => ready);

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      if (!stripe || !elements) {
        throw new Error('Stripe não está carregado. Por favor, tente novamente mais tarde.');
      }

      const cardNumber = elements.getElement(CardNumberElement);
      if (!cardNumber) {
        throw new Error('Elementos do cartão não encontrados. Por favor, recarregue a página.');
      }

      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      if (paymentMethod) {
        await onSubmit(paymentMethod.id);
      }
    } catch (error: any) {
      trackEvent('payment_error', {
        action: 'create_payment_method',
        error_message: error.message,
      });
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  // Simplified Stripe Element styles
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        '::placeholder': {
          color: theme.palette.mode === 'dark' ? '#aaa' : '#666',
        },
      },
      invalid: {
        color: '#ff1744',
        iconColor: '#ff1744',
      },
    },
  };

  // Simple wrapper style using MUI system props
  const cardElementWrapperStyle = {
    p: 2,
    border: 1,
    borderColor: 'divider',
    borderRadius: 1,
    bgcolor: 'background.paper',
  };

  return (
    <>
      <DialogContent sx={{ pt: 2, pb: 2 }}>
        <Box sx={{ position: 'relative', minHeight: 'auto' }}>
          {(!allElementsReady || isProcessing) && <Loading overlay />}

          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Pagamento processado de forma segura pela Stripe. Nenhuma informação do cartão é
            armazenada em nossos servidores.
          </Typography>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Número do Cartão
            </Typography>
            <Box sx={cardElementWrapperStyle}>
              <CardNumberElement
                options={cardElementOptions}
                onReady={() => setElementsReady((prev) => ({ ...prev, cardNumber: true }))}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: '2 1 auto' }}>
              <Typography variant="subtitle1" gutterBottom>
                Data de Expiração
              </Typography>
              <Box sx={cardElementWrapperStyle}>
                <CardExpiryElement
                  options={cardElementOptions}
                  onReady={() => setElementsReady((prev) => ({ ...prev, cardExpiry: true }))}
                />
              </Box>
            </Box>
            <Box sx={{ flex: '1 1 auto' }}>
              <Typography variant="subtitle1" gutterBottom>
                CVC
              </Typography>
              <Box sx={cardElementWrapperStyle}>
                <CardCvcElement
                  options={cardElementOptions}
                  onReady={() => setElementsReady((prev) => ({ ...prev, cardCvc: true }))}
                />
              </Box>
            </Box>
          </Box>

          {externalError && (
            <MessageBox variant="error" sx={{ mb: 1 }}>
              {externalError}
            </MessageBox>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isProcessing}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!stripe || isProcessing || !allElementsReady}
        >
          {isProcessing ? 'Processando...' : 'Salvar Método de Pagamento'}
        </Button>
      </DialogActions>
    </>
  );
};

// Main component that provides Stripe context
const CardDialog: React.FC<CardDialogProps> = ({ open, stripe, ...props }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pr: 6 }}>
        Adicionar Método de Pagamento
        <IconButton
          onClick={props.onClose}
          size="small"
          aria-label="fechar"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Elements stripe={stripe}>
        <CardDialogContent {...props} />
      </Elements>
    </Dialog>
  );
};

export default CardDialog;
