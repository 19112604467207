import MessageBox from '@components/Common/MessageBox';
import { useAnalytics } from '@hooks/useAnalytics';
import {
  Close as CloseIcon,
  Description as DescriptionIcon,
  Launch as LaunchIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { PortalServiceFactory } from '@services/portals/PortalServiceFactory';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao, LicitacaoStep } from '@types';
import React, { useEffect, useState } from 'react';
import { ApplyStep, DocumentsStep, DoneStep, DoubtsStep, ItemsStep, LoginStep } from './Steps';

interface StepsModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: Licitacao;
  initialStep?: number;
}

const steps: { label: string; key: LicitacaoStep }[] = [
  { label: 'Cadastro', key: 'login' },
  { label: 'Dúvidas', key: 'doubts' },
  { label: 'Itens', key: 'items' },
  { label: 'Documentos', key: 'documents' },
  { label: 'Proposta', key: 'apply' },
  { label: 'Concluído', key: 'done' },
];

const StepsModal: React.FC<StepsModalProps> = ({ open, onClose, licitacao, initialStep = 0 }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(initialStep);
  const portalService = PortalServiceFactory.create(licitacao);
  const { isFollowing, addFollowing } = useFollowingStore();
  const { isFavorite, addFavorite } = useFavoritesStore();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  const handleFavoriteAndFollow = async () => {
    try {
      if (!isFavorite(licitacao.id)) {
        await addFavorite(licitacao.id);
      }
      await addFollowing(licitacao.id);
    } catch (error) {
      trackEvent('following_error', {
        action: 'favorite_follow',
        licitacao_id: licitacao.id,
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const renderFollowAlert = () => (
    <MessageBox variant="warning" title="Atenção" sx={{ mb: 3 }}>
      Para prosseguir com os passos da licitação, é necessário favoritar e acompanhar este processo.
      Isso permitirá que você receba atualizações e gerencie os itens de interesse.
      <Button
        color="warning"
        size="small"
        variant="outlined"
        onClick={handleFavoriteAndFollow}
        startIcon={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <StarIcon fontSize="small" />
            <VisibilityIcon fontSize="small" />
          </Box>
        }
        sx={{ mt: 2 }}
      >
        {!isFavorite(licitacao.id) ? 'Favoritar e Acompanhar' : 'Acompanhar'}
      </Button>
    </MessageBox>
  );

  const handleNext = () => {
    if (!isFollowing(licitacao.id)) {
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  const handleEditalClick = () => {};

  const handlePortalClick = () => {};

  const renderStepContent = (step: number) => {
    const currentStep = steps[step];
    if (!currentStep) return null;
    const portalName = licitacao.portal || 'do portal';

    switch (currentStep.key) {
      case 'login':
        return <LoginStep portalService={portalService} portalName={portalName} />;
      case 'doubts':
        return (
          <DoubtsStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      case 'apply':
        return (
          <ApplyStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      case 'items':
        return <ItemsStep licitacao={licitacao} portalService={portalService} />;
      case 'documents':
        return <DocumentsStep licitacao={licitacao} portalService={portalService} />;
      case 'done':
        return (
          <DoneStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      default:
        return (
          <Box
            sx={{
              mt: 2,
              '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
            }}
          >
            Não há orientação disponível para este passo
          </Box>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          bgcolor: theme.palette.background.default,
          width: isMobile ? '100%' : '80vw',
          height: isMobile ? '100%' : '80vh',
          maxHeight: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          m: isMobile ? 0 : 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          p: isMobile ? 1 : 2,
          pb: isMobile ? 0 : 2,
          position: 'sticky',
          top: 0,
          bgcolor: theme.palette.background.default,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              width: '100%',
            }}
          >
            <Typography variant={isMobile ? 'subtitle1' : 'h6'} component="div" sx={{ mr: 'auto' }}>
              Participar da Licitação
            </Typography>

            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<DescriptionIcon />}
              href={portalService.getEditalUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleEditalClick}
            >
              {isMobile ? 'Edital' : 'Consultar Edital'}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<LaunchIcon />}
              href={portalService.getPortalUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handlePortalClick}
            >
              {isMobile ? 'Portal' : 'Acompanhar no portal'}
            </Button>

            <IconButton onClick={onClose} size="small" sx={{ p: 0.5 }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{
              overflowX: 'auto',
              '& .MuiStepLabel-label': {
                fontSize: '0.75rem',
              },
              '& .MuiStepButton-root': {
                py: 0.5,
                px: 1,
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.25rem',
              },
              mt: 1,
            }}
          >
            {steps.map(({ label }, index) => (
              <Step key={label}>
                <StepButton onClick={() => handleStepClick(index)}>
                  <StepLabel>{isMobile ? label.split(' ')[0] : label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          p: isMobile ? 1 : 2,
          overflow: 'auto',
          flex: 1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: '100%',
          maxHeight: 'calc(100% - 120px)',
        }}
      >
        {!isFollowing(licitacao.id) && activeStep >= 2
          ? renderFollowAlert()
          : renderStepContent(activeStep)}
      </DialogContent>

      <Box
        sx={{
          p: 1,
          bgcolor: 'background.paper',
          borderTop: 1,
          borderColor: 'divider',
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Button
            fullWidth
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            size="small"
          >
            Voltar
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={activeStep === steps.length - 1 ? onClose : handleNext}
            size="small"
          >
            {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default StepsModal;
