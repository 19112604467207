import { Box, Paper, Skeleton } from '@mui/material';

const BulletinCardSkeleton: React.FC = () => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Skeleton variant="text" width="70%" height={32} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
      <Skeleton variant="text" width={120} height={24} sx={{ mb: 1 }} />
      <Box display="flex" gap={1}>
        {[...Array(3)].map((_, i) => (
          <Skeleton key={i} variant="rounded" width="33%" height={64} />
        ))}
      </Box>
    </Paper>
  );
};

export default BulletinCardSkeleton;
