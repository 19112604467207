import { DurationId, FeatureConfig, FeatureId, Plan, PlanDuration, PlanId } from '@types';

export const features: Record<FeatureId, FeatureConfig> = {
  [FeatureId.MULTI_PORTAL_SEARCH]: {
    id: FeatureId.MULTI_PORTAL_SEARCH,
    name: 'Pesquisa',
    title: 'Pesquisa de Editais em Múltiplos Portais',
    description: 'Busque editais em diversos portais simultaneamente',
    helpText:
      'Acesse ComprasNet, PNCP, PCP, BLL, BNC, ComprasBR, LicitaNET, Licitar Digital, Banrisul, DOU, LCON, PNET, SIGA RJ e outros portais em um só lugar',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.DAILY_ALERTS]: {
    id: FeatureId.DAILY_ALERTS,
    name: 'Alertas',
    title: 'Alertas Diários Personalizados',
    description: 'Receba alertas de novas licitações baseados em seus critérios',
    helpText: 'Configure palavras-chave, valores e regiões de interesse',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.INTEGRATED_CALENDAR]: {
    id: FeatureId.INTEGRATED_CALENDAR,
    name: 'Agenda',
    title: 'Agenda de Licitações Integrada',
    description: 'Calendário completo com prazos e eventos importantes',
    helpText: 'Visualize e gerencie todas as datas importantes das suas licitações',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.FAVORITES]: {
    id: FeatureId.FAVORITES,
    name: 'Favoritos',
    title: 'Sistema de Favoritos com Notificações',
    description: 'Salve e acompanhe licitações de interesse',
    helpText: 'Receba notificações sobre atualizações em licitações favoritadas',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
    maxUsage: {
      [PlanId.BUSCA]: 50,
      [PlanId.PREMIUM]: 150,
      [PlanId.MULTI_EMPRESA]: 150,
    },
  },
  [FeatureId.REALTIME_TRACKING]: {
    id: FeatureId.REALTIME_TRACKING,
    name: 'Acompanhamento',
    title: 'Acompanhamento em Tempo Real',
    description: 'Monitore alterações e atualizações em tempo real',
    helpText: 'Seja notificado instantaneamente sobre mudanças em editais e processos',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
    maxUsage: {
      [PlanId.BUSCA]: 50,
      [PlanId.PREMIUM]: 150,
      [PlanId.MULTI_EMPRESA]: 150,
    },
  },
  [FeatureId.BASIC_DOCS]: {
    id: FeatureId.BASIC_DOCS,
    name: 'Documentos',
    title: 'Gerenciamento de Documentos Básico',
    description: 'Organize seus documentos essenciais',
    helpText: 'Mantenha seus documentos organizados e acessíveis',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },

  [FeatureId.BULLETINS]: {
    id: FeatureId.BULLETINS,
    name: 'Boletins',
    title: 'Boletins Personalizados',
    description: 'Crie boletins para monitorar licitações',
    helpText: 'Configure boletins para receber alertas personalizados',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
    maxUsage: {
      [PlanId.BUSCA]: 5,
      [PlanId.PREMIUM]: 15,
      [PlanId.MULTI_EMPRESA]: 15,
    },
  },
  [FeatureId.CHAT_SUPPORT]: {
    id: FeatureId.CHAT_SUPPORT,
    name: 'Suporte',
    title: 'Suporte por Chat',
    description: 'Suporte técnico via chat',
    helpText: 'Tire suas dúvidas com nossa equipe de suporte',
    availableIn: [PlanId.BUSCA, PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },

  // Premium features
  [FeatureId.AI_ANALYSIS]: {
    id: FeatureId.AI_ANALYSIS,
    name: 'Análise',
    title: 'Análise de Editais com IA Avançada',
    description: 'Análise automática de editais usando inteligência artificial',
    helpText: 'Nossa IA identifica requisitos, prazos e pontos críticos automaticamente',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
    beta: true,
  },
  [FeatureId.AUTO_DECLARATIONS]: {
    id: FeatureId.AUTO_DECLARATIONS,
    name: 'Declarações',
    title: 'Geração Automática de Declarações',
    description: 'Gere declarações automaticamente',
    helpText: 'Crie declarações personalizadas com base nos requisitos do edital',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.DOC_VERIFICATION]: {
    id: FeatureId.DOC_VERIFICATION,
    name: 'Verificação',
    title: 'Verificação de Documentos Necessários',
    description: 'Análise automática de documentação necessária',
    helpText: 'Identifique automaticamente quais documentos são necessários para cada licitação',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.ADVANCED_RECOMMENDATIONS]: {
    id: FeatureId.ADVANCED_RECOMMENDATIONS,
    name: 'Recomendações',
    title: 'Recomendações com IA',
    description: 'Sugestões inteligentes de licitações',
    helpText: 'Receba recomendações personalizadas baseadas no seu perfil',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.PERSONAL_TRAINING]: {
    id: FeatureId.PERSONAL_TRAINING,
    name: 'Treinamento',
    title: 'Treinamento Personalizado',
    description: 'Sessões de treinamento com consultores especializados',
    helpText: 'Agende sessões de treinamento para maximizar o uso da plataforma',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },
  [FeatureId.PRIORITY_SUPPORT]: {
    id: FeatureId.PRIORITY_SUPPORT,
    name: 'Suporte',
    title: 'Suporte Prioritário',
    description: 'Atendimento prioritário para suas demandas',
    helpText: 'Tenha suas questões resolvidas com prioridade máxima',
    availableIn: [PlanId.PREMIUM, PlanId.MULTI_EMPRESA],
  },

  // Multi-empresa features
  [FeatureId.MULTI_CNPJ]: {
    id: FeatureId.MULTI_CNPJ,
    name: 'CNPJ',
    title: 'Gerenciamento Multi-CNPJ',
    description: 'Gerencie até 5 CNPJs diferentes',
    helpText: 'Administre múltiplas empresas em uma única conta',
    availableIn: [PlanId.MULTI_EMPRESA],
    maxUsage: {
      [PlanId.MULTI_EMPRESA]: 5,
    },
  },
  [FeatureId.MULTI_COMPANY_DASHBOARD]: {
    id: FeatureId.MULTI_COMPANY_DASHBOARD,
    name: 'Painel',
    title: 'Painel Multi-Empresa',
    description: 'Visão consolidada de todas as empresas',
    helpText: 'Acompanhe todas as suas empresas em um único painel',
    availableIn: [PlanId.MULTI_EMPRESA],
  },
  [FeatureId.MULTI_USER]: {
    id: FeatureId.MULTI_USER,
    name: 'Usuários',
    title: 'Até 5 Usuários',
    description: 'Tenha até 5 pessoas usando ao mesmo tempo',
    helpText: 'Esse plano permite que você gerencie até 5 usuários',
    availableIn: [PlanId.MULTI_EMPRESA],
    maxUsage: {
      [PlanId.MULTI_EMPRESA]: 5,
    },
    comingSoon: true,
  },
  [FeatureId.ADVANCED_REPORTS]: {
    id: FeatureId.ADVANCED_REPORTS,
    name: 'Relatórios',
    title: 'Relatórios Avançados',
    description: 'Relatórios detalhados multi-empresa',
    helpText: 'Gere relatórios consolidados de todas as suas empresas',
    availableIn: [PlanId.MULTI_EMPRESA],
    comingSoon: true,
  },
  [FeatureId.DEDICATED_SUPPORT]: {
    id: FeatureId.DEDICATED_SUPPORT,
    name: 'Suporte',
    title: 'Suporte Dedicado',
    description: 'Consultor dedicado para sua empresa',
    helpText: 'Conte com um consultor exclusivo para suas necessidades',
    availableIn: [PlanId.MULTI_EMPRESA],
  },
};

// Utility function to check feature access
export const hasFeatureAccess = (
  featureId: FeatureId,
  userPlan: PlanId | null,
  usage?: number,
): {
  hasAccess: boolean;
  reason?: string;
} => {
  if (!userPlan) return { hasAccess: false, reason: 'Nenhum plano ativo' };

  const feature = features[featureId];
  if (!feature) return { hasAccess: false, reason: 'Recurso não encontrado' };

  if (feature.comingSoon) {
    return { hasAccess: false, reason: 'Recurso em desenvolvimento' };
  }

  if (!feature.availableIn.includes(userPlan)) {
    return {
      hasAccess: false,
      reason: `Disponível apenas nos planos: ${feature.availableIn
        .map((planId) => plans.find((p) => p.id === planId)?.name)
        .join(', ')}`,
    };
  }

  if (feature.maxUsage && usage !== undefined) {
    const limit = feature.maxUsage[userPlan];
    if (limit !== undefined && usage >= limit) {
      return {
        hasAccess: false,
        reason: `Limite de ${limit} atingido no seu plano`,
      };
    }
  }

  return { hasAccess: true };
};

export const plans: Plan[] = [
  {
    id: 'busca' as PlanId,
    name: 'Busca',
    price: ['R$79,90', 'R$439,90', 'R$799,00'],
    originalPrice: ['R$99,90', 'R$549,90', 'R$999,00'],
    priceInCents: [7990, 43990, 79900],
    duration: 'mensal',
    mainFeature: 'Para Pequenas Empresas',
    description: 'Ideal para empresas iniciando no mundo das licitações',
    features: [
      FeatureId.MULTI_PORTAL_SEARCH,
      FeatureId.DAILY_ALERTS,
      FeatureId.INTEGRATED_CALENDAR,
      FeatureId.FAVORITES,
      FeatureId.REALTIME_TRACKING,
      FeatureId.BASIC_DOCS,
      FeatureId.BULLETINS,
      FeatureId.CHAT_SUPPORT,

      // Premium features
      false, // FeatureId.AI_ANALYSIS,
      false, // FeatureId.AUTO_DECLARATIONS,
      false, // FeatureId.DOC_VERIFICATION,
      false, // FeatureId.ADVANCED_RECOMMENDATIONS,
      false, // FeatureId.PERSONAL_TRAINING,
      false, // FeatureId.PRIORITY_SUPPORT,
      // Multi-empresa features
      false, // FeatureId.MULTI_CNPJ,
      false, // FeatureId.MULTI_COMPANY_DASHBOARD,
      false, // FeatureId.MULTI_USER,
      false, // FeatureId.ADVANCED_REPORTS,
      false, // FeatureId.DEDICATED_SUPPORT,
    ],
    durationPrices: ['R$79,90', 'R$439,90', 'R$799,00'],
  },
  {
    id: 'premium' as PlanId,
    name: 'Premium',
    price: ['R$149,90', 'R$790,00', 'R$1490,00'],
    originalPrice: ['R$189,90', 'R$990,00', 'R$1890,00'],
    priceInCents: [14990, 79000, 149000],
    duration: 'mensal',
    mainFeature: 'Para Empresas em Crescimento',
    description: 'Recursos avançados para aumentar suas chances de sucesso',
    features: [
      FeatureId.MULTI_PORTAL_SEARCH,
      FeatureId.DAILY_ALERTS,
      FeatureId.INTEGRATED_CALENDAR,
      FeatureId.FAVORITES,
      FeatureId.REALTIME_TRACKING,
      FeatureId.BASIC_DOCS,
      FeatureId.BULLETINS,
      FeatureId.CHAT_SUPPORT,

      // Premium features
      FeatureId.AI_ANALYSIS,
      FeatureId.AUTO_DECLARATIONS,
      FeatureId.DOC_VERIFICATION,
      FeatureId.ADVANCED_RECOMMENDATIONS,
      FeatureId.PERSONAL_TRAINING,
      FeatureId.PRIORITY_SUPPORT,

      // Multi-empresa features
      false, // FeatureId.MULTI_CNPJ,
      false, // FeatureId.MULTI_COMPANY_DASHBOARD,
      false, // FeatureId.MULTI_USER,
      false, // FeatureId.ADVANCED_REPORTS,
      false, // FeatureId.DEDICATED_SUPPORT,
    ],
    featured: true,
    durationPrices: ['R$149,90', 'R$790,00', 'R$1490,00'],
  },
  {
    id: 'multi_empresa' as PlanId,
    name: 'Multi-Empresa',
    price: ['R$429,90', 'R$1690,00', 'R$2990,00'],
    originalPrice: ['R$539,90', 'R$2190,00', 'R$3890,00'],
    priceInCents: [42990, 169000, 299000],
    duration: 'mensal',
    mainFeature: 'Para Grandes Empresas',
    description: 'Solução completa para gerenciar múltiplas empresas',
    features: [
      FeatureId.MULTI_PORTAL_SEARCH,
      FeatureId.DAILY_ALERTS,
      FeatureId.INTEGRATED_CALENDAR,
      FeatureId.FAVORITES,
      FeatureId.REALTIME_TRACKING,
      FeatureId.BASIC_DOCS,
      FeatureId.BULLETINS,
      FeatureId.CHAT_SUPPORT,

      // Premium features
      FeatureId.AI_ANALYSIS,
      FeatureId.AUTO_DECLARATIONS,
      FeatureId.DOC_VERIFICATION,
      FeatureId.ADVANCED_RECOMMENDATIONS,
      FeatureId.PERSONAL_TRAINING,
      FeatureId.PRIORITY_SUPPORT,

      // Multi-empresa features
      FeatureId.MULTI_CNPJ,
      FeatureId.MULTI_COMPANY_DASHBOARD,
      FeatureId.MULTI_USER,
      FeatureId.ADVANCED_REPORTS,
      FeatureId.DEDICATED_SUPPORT,
    ],

    // inDevelopment: true,

    durationPrices: ['R$429,90', 'R$1690,00', 'R$2990,00'],
  },
];

export const planDurations: PlanDuration[] = [
  {
    id: 'mensal' as DurationId,
    switcherText: 'Mensal',
    text: 'Mensal',
  },
  {
    id: 'semestral' as DurationId,
    switcherText: 'Semestral',
    text: 'Semestral',
  },
  {
    id: 'anual' as DurationId,
    switcherText: 'Anual',
    text: 'Anual',
  },
];

export const getPlanHighlights = (planId: PlanId) => {
  switch (planId) {
    case PlanId.BUSCA:
      return [
        FeatureId.MULTI_PORTAL_SEARCH,
        FeatureId.DAILY_ALERTS,
        FeatureId.INTEGRATED_CALENDAR,
        FeatureId.BULLETINS,
      ];
    case PlanId.PREMIUM:
      return [
        FeatureId.MULTI_PORTAL_SEARCH,
        FeatureId.AI_ANALYSIS,
        FeatureId.AUTO_DECLARATIONS,
        FeatureId.PRIORITY_SUPPORT,
      ];
    case PlanId.MULTI_EMPRESA:
      return [
        FeatureId.MULTI_CNPJ,
        FeatureId.MULTI_USER,
        FeatureId.AI_ANALYSIS,
        FeatureId.DEDICATED_SUPPORT,
      ];
    default:
      return [];
  }
};

export const getPlanById = (id: PlanId | null | undefined): Plan | undefined => {
  if (!id) return undefined;
  return plans.find((plan) => plan.id === id);
};

export const getPlanDurationById = (
  id: DurationId | null | undefined,
): PlanDuration | undefined => {
  if (!id) return undefined;
  return planDurations.find((duration) => duration.id === id);
};
