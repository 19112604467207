import api from '@services/api';
import {
  AnyPaymentMethod,
  BoletoPaymentResponse,
  CurrentPlan,
  DurationId,
  PaymentHistoryItem,
  PlanId,
} from '@types';
import { create } from 'zustand';

interface PaymentState {
  currentPlans: CurrentPlan[];
  nextBillingDates: string[];
  paymentHistory: PaymentHistoryItem[];
  paymentMethods: AnyPaymentMethod[];
  isLoading: boolean;
  error: string | null;
  setError: (error: string | null) => void;
  fetchPaymentInfo: () => Promise<void>;
  setDefaultPaymentMethod: (paymentMethodId: string) => Promise<void>;
  addPaymentMethod: (paymentMethodId: string) => Promise<void>;
  cancelSubscription: (subscriptionId: string) => Promise<void>;
  reactivateSubscription: (subscriptionId: string) => Promise<void>;
  resetStore: () => void;
  confirmPaymentAndCreateSubscription: (
    planId: PlanId,
    duration: DurationId,
    paymentMethodId: string,
  ) => Promise<void>;
  switchSubscriptionPlan: (
    subscriptionId: string,
    newPlanId: PlanId,
    newDurationId: DurationId,
  ) => Promise<void>;
  switchToBoleto: (subscriptionId: string) => Promise<void>;
  createBoletoSubscription: (
    planId: PlanId,
    durationId: DurationId,
  ) => Promise<BoletoPaymentResponse>;
  changePlanWithBoleto: (
    newPlanId: PlanId,
    newDurationId: DurationId,
  ) => Promise<BoletoPaymentResponse>;
  defaultPaymentMethod: () => 'card' | 'boleto' | null;
  getBoletoSuccessTitle: (action?: 'charge' | 'refund' | 'no_charge') => string;
}

export const formatStatus = (
  status: string,
  cancelAtPeriodEnd: boolean,
  canceledAt: string | null,
): string => {
  if (canceledAt) {
    return 'Cancelado (acesso até o fim do período)';
  }
  switch (status) {
    case 'trialing':
    case 'active':
      return cancelAtPeriodEnd ? 'Ativo (Cancelamento agendado)' : 'Ativo';
    case 'canceled':
      return 'Cancelado';
    case 'incomplete':
      return 'Incompleto';
    case 'incomplete_expired':
      return 'Expirado';
    case 'past_due':
      return 'Pagamento atrasado';
    case 'unpaid':
      return 'Não pago';
    default:
      return 'Desconhecido';
  }
};

export const usePaymentStore = create<PaymentState>((set, get) => ({
  currentPlans: [],
  nextBillingDates: [],
  paymentHistory: [],
  paymentMethods: [],
  isLoading: false,
  error: null,
  setError: (error) => set({ error }),

  fetchPaymentInfo: async () => {
    set({ isLoading: true, error: null });
    try {
      const paymentInfo = await api.getPaymentInfo();
      set({
        currentPlans: paymentInfo.currentPlans,
        nextBillingDates: paymentInfo.nextBillingDates,
        paymentHistory: paymentInfo.paymentHistory,
        paymentMethods: paymentInfo.paymentMethods as AnyPaymentMethod[],
        isLoading: false,
      });
    } catch (error: any) {
      console.error('Error fetching payment info:', error);
      const errorMessage = error.message || 'Erro ao buscar informações de pagamento';
      set({ error: errorMessage, isLoading: false });
      throw error;
    }
  },

  setDefaultPaymentMethod: async (paymentMethodId: string) => {
    const state = get();
    const existingBoleto = state.paymentMethods.find((m) => m.type === 'boleto');

    // Handle 'boleto' special case
    const finalPaymentMethodId =
      paymentMethodId === 'boleto' ? existingBoleto?.id || 'boleto' : paymentMethodId;

    const previousPaymentMethods = [...state.paymentMethods];

    set({ isLoading: true, error: null });
    try {
      await api.setDefaultPaymentMethod(finalPaymentMethodId);
      await state.fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error: any) {
      console.error('Error setting default payment method:', error);
      const errorMessage = error.message || 'Erro ao atualizar o método de pagamento';
      set({ error: errorMessage, paymentMethods: previousPaymentMethods, isLoading: false });
      throw error;
    }
  },

  addPaymentMethod: async (paymentMethodId: string) => {
    const previousPaymentMethods = [...get().paymentMethods];
    set({ isLoading: true, error: null });
    try {
      await api.addPaymentMethod(paymentMethodId);
      await get().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error: any) {
      console.error('Error adding payment method:', error);
      const errorMessage = error.message || 'Erro ao adicionar o método de pagamento';
      set({ error: errorMessage, paymentMethods: previousPaymentMethods, isLoading: false });
      throw error;
    }
  },

  cancelSubscription: async (subscriptionId: string) => {
    const previousPlans = [...get().currentPlans];
    set({ isLoading: true, error: null });
    try {
      const result = await api.cancelSubscription(subscriptionId);
      set((state) => ({
        currentPlans: state.currentPlans.map((plan) =>
          plan.id === subscriptionId
            ? {
                ...plan,
                status: result.status,
                cancelAtPeriodEnd: result.status === 'active',
              }
            : plan,
        ),
        isLoading: false,
      }));
    } catch (error: any) {
      console.error('Error canceling subscription:', error);
      const errorMessage = error.message || 'Erro ao cancelar a assinatura';
      set({ error: errorMessage, currentPlans: previousPlans, isLoading: false });
      throw error;
    }
  },

  reactivateSubscription: async (subscriptionId: string) => {
    set({ isLoading: true, error: null });
    try {
      await api.reactivateSubscription(subscriptionId);
      await get().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error: any) {
      const errorMessage = error.message || 'Erro ao reativar a assinatura';
      set({ error: errorMessage, isLoading: false });
      throw error;
    }
  },

  confirmPaymentAndCreateSubscription: async (
    planId: PlanId,
    duration: DurationId,
    paymentMethodId: string,
  ) => {
    const previousState = {
      currentPlans: [...get().currentPlans],
      paymentHistory: [...get().paymentHistory],
    };
    set({ isLoading: true, error: null });
    try {
      const result = await api.createSubscription(planId, duration, paymentMethodId);
      if (result.error) {
        throw new Error(result.message || result.error);
      }
      await get().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error: any) {
      console.error('Error confirming payment and creating subscription:', error);
      const errorMessage = error.message || 'Erro ao processar pagamento e criar assinatura';
      set({ ...previousState, error: errorMessage, isLoading: false });
      throw error;
    }
  },

  resetStore: () =>
    set({
      currentPlans: [],
      nextBillingDates: [],
      paymentHistory: [],
      paymentMethods: [],
      isLoading: false,
      error: null,
    }),

  switchSubscriptionPlan: async (
    subscriptionId: string,
    newPlanId: PlanId,
    newDurationId: DurationId,
  ) => {
    const previousPlans = [...get().currentPlans];
    set({ isLoading: true, error: null });
    try {
      const result = await api.switchSubscriptionPlan(subscriptionId, newPlanId, newDurationId);
      set((state) => ({
        currentPlans: state.currentPlans.map((plan) =>
          plan.id === subscriptionId
            ? {
                ...plan,
                planId: newPlanId,
                durationId: newDurationId,
                status: result.status,
              }
            : plan,
        ),
        isLoading: false,
      }));
      await get().fetchPaymentInfo();
    } catch (error: any) {
      console.error('Error switching subscription plan:', error);
      const errorMessage = error.message || 'Erro ao alterar o plano';
      set({ error: errorMessage, currentPlans: previousPlans, isLoading: false });
      throw error;
    }
  },

  createBoletoSubscription: async (planId, durationId) => {
    set({ isLoading: true, error: null });
    try {
      const result = await api.createBoletoSubscription(planId, durationId);
      set({ isLoading: false });
      return result;
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error;
    }
  },

  switchToBoleto: async (subscriptionId: string) => {
    set({ isLoading: true, error: null });
    try {
      await api.switchToBoleto(subscriptionId);
      await get().fetchPaymentInfo();
      set({ isLoading: false });
    } catch (error: any) {
      console.error('Error switching to boleto:', error);
      const errorMessage = error.message || 'Erro ao trocar para boleto';
      set({ error: errorMessage, isLoading: false });
      throw error;
    }
  },

  changePlanWithBoleto: async (newPlanId, newDurationId) => {
    set({ isLoading: true, error: null });
    try {
      const result = await api.changePlanWithBoleto(newPlanId, newDurationId);
      set({ isLoading: false });
      return result;
    } catch (error: any) {
      set({ error: error.message, isLoading: false });
      throw error;
    }
  },

  defaultPaymentMethod: () => {
    const { paymentMethods } = get();
    return paymentMethods.find((m) => m.isDefault)?.type || null;
  },

  // Helper function for boleto success title
  getBoletoSuccessTitle: (action?: 'charge' | 'refund' | 'no_charge') => {
    switch (action) {
      case 'charge':
        return 'Boleto Gerado';
      case 'refund':
        return 'Crédito Confirmado';
      case 'no_charge':
        return 'Alteração Confirmada';
      default:
        return 'Processamento Concluído';
    }
  },
}));
