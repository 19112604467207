import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

export interface MaskedInputProps extends Omit<TextFieldProps, 'onChange'> {
  mask: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput: React.FC<MaskedInputProps> = ({ mask, value, onChange, ...props }) => {
  return (
    <TextField
      {...props}
      value={value}
      sx={{
        '& .MuiInputBase-input': {
          color: value ? 'inherit' : 'transparent',
        },
        ...props.sx,
      }}
      InputProps={{
        inputComponent: IMaskInput as any,
        inputProps: {
          mask,
          definitions: {
            '9': /[0-9]/,
          },
          lazy: true,
          placeholderChar: '_',
          overwrite: 'shift',
          prepare: (str: string) => {
            if (mask === '99.999.999-9') {
              const digits = str.replace(/\D/g, '');
              if (digits.length === 8) {
                return (
                  '0' +
                  digits.slice(0, 1) +
                  '.' +
                  digits.slice(1, 4) +
                  '.' +
                  digits.slice(4, 7) +
                  '-' +
                  digits.slice(7)
                );
              }
              if (digits.length === 9) {
                return (
                  digits.slice(0, 2) +
                  '.' +
                  digits.slice(2, 5) +
                  '.' +
                  digits.slice(5, 8) +
                  '-' +
                  digits.slice(8)
                );
              }
            }
            return str;
          },
          onAccept: (value: string) => {
            const syntheticEvent = {
              target: {
                name: props.name || '',
                value: value.replace(/_/g, ''),
              },
            } as React.ChangeEvent<HTMLInputElement>;

            onChange(syntheticEvent);
          },
        },
      }}
    />
  );
};

export default MaskedInput;
