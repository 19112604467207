import { Box, Container, useTheme } from '@mui/material';
import React from 'react';
import Header from './Header';
import RotatingLogo from './RotatingLogo';

interface LoadingProps {
  withHeader?: boolean;
  overlay?: boolean;
  sx?: any;
}

const Loading: React.FC<LoadingProps> = ({ withHeader = false, overlay = true, sx }) => {
  const theme = useTheme();

  const loadingContent = (
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        width: '100%',
        height: '100%',
        minHeight: withHeader ? 'calc(100vh - 64px)' : overlay ? '200px' : '100vh',
        zIndex: theme.zIndex.modal + 1,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          ...(overlay && {
            backgroundColor: `${theme.palette.background.default}dd`,
            backdropFilter: 'blur(4px)',
          }),
        }}
      >
        <Box
          sx={{
            mb: 2,
            ...(overlay && {
              animation: 'pulse 2s infinite',
              '@keyframes pulse': {
                '0%': { opacity: 0.6 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.6 },
              },
            }),
          }}
        >
          <RotatingLogo color={theme.palette.primary.main} />
        </Box>
      </Box>
    </Box>
  );

  if (withHeader) {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
      >
        <Header isLoading={true} />
        <Container maxWidth={false} disableGutters>
          {loadingContent}
        </Container>
      </Box>
    );
  }

  return loadingContent;
};

export default Loading;
