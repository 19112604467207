import { LicitacaoStatus } from '@types';

export const STATUS_LABELS: Record<LicitacaoStatus, string> = {
  [LicitacaoStatus.DRAFT]: 'Rascunho',
  [LicitacaoStatus.PUBLISHED]: 'Publicado',
  [LicitacaoStatus.RECEIVING_PROPOSALS]: 'Recebendo Propostas',
  [LicitacaoStatus.IN_PROGRESS]: 'Em Andamento',
  [LicitacaoStatus.SUSPENDED]: 'Suspenso',
  [LicitacaoStatus.CANCELLED]: 'Cancelado',
  [LicitacaoStatus.DESERTED]: 'Deserto',
  [LicitacaoStatus.FAILED]: 'Fracassado',
  [LicitacaoStatus.AWARDED]: 'Adjudicado',
  [LicitacaoStatus.HOMOLOGATED]: 'Homologado',
  [LicitacaoStatus.UNKNOWN]: 'Desconhecido',
  [LicitacaoStatus.CLOSED]: 'Fechado',
};

export const STATUS_COLORS: Record<LicitacaoStatus, string> = {
  [LicitacaoStatus.DRAFT]: '#6B7280', // Gray
  [LicitacaoStatus.PUBLISHED]: '#3B82F6', // Blue
  [LicitacaoStatus.RECEIVING_PROPOSALS]: '#10B981', // Green
  [LicitacaoStatus.IN_PROGRESS]: '#F59E0B', // Yellow
  [LicitacaoStatus.SUSPENDED]: '#EF4444', // Red
  [LicitacaoStatus.CANCELLED]: '#DC2626', // Dark Red
  [LicitacaoStatus.DESERTED]: '#6B7280', // Gray
  [LicitacaoStatus.FAILED]: '#DC2626', // Dark Red
  [LicitacaoStatus.AWARDED]: '#059669', // Dark Green
  [LicitacaoStatus.HOMOLOGATED]: '#047857', // Darker Green
  [LicitacaoStatus.UNKNOWN]: '#6B7280', // Gray
  [LicitacaoStatus.CLOSED]: '#1F2937', // Dark Gray
};

export enum BulletinPeriod {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export const PERIOD_TIMES = {
  [BulletinPeriod.MORNING]: {
    startTime: '00:00:00.000Z',
    endTime: '10:00:00.000Z',
  },
  [BulletinPeriod.AFTERNOON]: {
    startTime: '10:00:00.000Z',
    endTime: '14:00:00.000Z',
  },
  [BulletinPeriod.EVENING]: {
    startTime: '14:00:00.000Z',
    endTime: '23:59:59.999Z',
  },
} as const;

export const PERIOD_LABELS: Record<BulletinPeriod, string> = {
  [BulletinPeriod.MORNING]: 'Manhã',
  [BulletinPeriod.AFTERNOON]: 'Tarde',
  [BulletinPeriod.EVENING]: 'Noite',
};

// Optional: Add a separate mapping for tooltips if we want to show the full info on hover
export const PERIOD_TOOLTIPS: Record<BulletinPeriod, string> = {
  [BulletinPeriod.MORNING]: 'Manhã (até 10h)',
  [BulletinPeriod.AFTERNOON]: 'Tarde (10h às 14h)',
  [BulletinPeriod.EVENING]: 'Final do dia (após 14h)',
};

export function getPeriodLabel(period: BulletinPeriod | string | undefined): string {
  if (!period) return '';
  return PERIOD_LABELS[period as BulletinPeriod] || '';
}
