import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { Add, CreditCard, Receipt } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { usePaymentStore, useProfileStore } from '@store';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { AnyPaymentMethod } from '@types';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardDialog from './CardDialog';
import TaxInfoDialog from './TaxInfoDialog';

interface PaymentMethodsProps {
  title?: string;
  flow: 'edit' | 'view';
  onSubmit?: (paymentMethodId: string, paymentType: 'card' | 'boleto') => void;
  onBack?: () => void;
}
if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error('REACT_APP_STRIPE_KEY is not set');
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  title = 'Métodos de Pagamento',
  flow = 'view',
  onSubmit = () => {},
  onBack = () => {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showNotification } = useNotification();
  const stripeRef = useRef<Stripe | null>(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { userData } = useProfileStore();
  const {
    paymentMethods,
    addPaymentMethod,
    nextBillingDates,
    defaultPaymentMethod,
    currentPlans,
    switchToBoleto,
    fetchPaymentInfo,
    setDefaultPaymentMethod,
  } = usePaymentStore();
  const { trackEvent } = useAnalytics();
  const [openBoletoDialog, setOpenBoletoDialog] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState<{
    open: boolean;
    paymentMethodId: string;
    paymentType: 'card' | 'boleto';
    title: string;
    message: string;
  }>({
    open: false,
    paymentMethodId: '',
    paymentType: 'card',
    title: '',
    message: '',
  });

  const currentDefaultMethod = defaultPaymentMethod();

  const handlePaymentMethodSelect = async (
    paymentMethodId: string,
    paymentType: 'card' | 'boleto',
  ) => {
    setSelectedPaymentMethodId(paymentMethodId);
    if (flow === 'edit') {
      onSubmit(paymentMethodId, paymentType);
    }
  };

  const loadStripeInstance = useCallback(async () => {
    if (!stripeRef.current) {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
      stripeRef.current = stripeInstance;
    }
  }, []);

  useEffect(() => {
    if (openDialog && !stripeRef.current) {
      loadStripeInstance();
    }
  }, [openDialog, loadStripeInstance]);

  const handleAddPaymentMethod = useCallback(
    async (paymentMethodId: string, paymentType: 'card' | 'boleto' = 'card') => {
      try {
        if (paymentType === 'card') {
          await addPaymentMethod(paymentMethodId);
        }
        trackEvent('payment_method_created', {
          is_first_method: !paymentMethods?.length,
          type: paymentType,
        });
        showNotification('Método de pagamento adicionado com sucesso', 'success');
        setOpenDialog(false);
        onSubmit(paymentMethodId, paymentType);
      } catch (error: any) {
        trackEvent('payment_error', {
          action: 'add_payment_method',
          error_message: error.message,
          type: paymentType,
        });
        return;
      }
    },
    [addPaymentMethod, onSubmit, showNotification],
  );

  useEffect(() => {
    if (paymentMethods.length > 0) {
      setSelectedPaymentMethodId(
        currentDefaultMethod === 'boleto'
          ? 'boleto'
          : paymentMethods.find((m) => m.isDefault && m.type === 'card')?.id ||
              paymentMethods[0].id,
      );
    }
  }, [paymentMethods, currentDefaultMethod]);

  const handleSubmit = () => {
    if (selectedPaymentMethodId) {
      const paymentType =
        selectedPaymentMethodId === 'boleto' ||
        paymentMethods.some((m) => m.id === selectedPaymentMethodId && m.type === 'boleto')
          ? 'boleto'
          : 'card';

      onSubmit(selectedPaymentMethodId, paymentType);
    } else {
      showNotification('Por favor, selecione um método de pagamento', 'warning');
    }
  };

  const handleOpenDialog = async () => {
    await loadStripeInstance();
    setOpenDialog(true);
  };

  const handleBack = () => {
    onBack();
  };

  const handleBoletoSubmit = async () => {
    try {
      if (!userData?.taxId || !userData?.taxName || !userData?.address) {
        throw new Error('Dados fiscais incompletos');
      }
      setSelectedPaymentMethodId('boleto');
      onSubmit('boleto', 'boleto');
      setOpenBoletoDialog(false);
      showNotification('Boleto configurado com sucesso!', 'success');
    } catch (error: any) {
      showNotification(error.message || 'Erro ao configurar boleto', 'error');
    }
  };

  const paymentMethodBoxStyles = (isSelected: boolean) => ({
    mb: 2,
    p: 1.5,
    border: isSelected
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    cursor: 'pointer',
    width: isMobile ? '100%' : '200px',
    height: isMobile ? 'auto' : '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[4],
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.default,
    },
    '&:active': {
      transform: 'translateY(0)',
      transition: 'transform 0.1s',
    },
  });

  const renderPaymentMethod = (method: AnyPaymentMethod) => (
    <Box
      key={method.id}
      sx={paymentMethodBoxStyles(selectedPaymentMethodId === method.id)}
      onClick={() =>
        handlePaymentMethodSelect(method.id, method.type === 'boleto' ? 'boleto' : 'card')
      }
    >
      <Typography variant={isMobile ? 'body1' : 'h6'}>
        {method.type === 'boleto' ? (
          <>
            <Receipt sx={{ mr: 1 }} />
            Boleto
          </>
        ) : (
          <>
            <CreditCard sx={{ mr: 1 }} />
            Cartão {method.brand.toUpperCase()}
          </>
        )}
      </Typography>
      <Typography variant={isMobile ? 'body2' : 'body1'} color="text.secondary" sx={{ mt: 2 }}>
        {method.type === 'boleto'
          ? nextBillingDates.length > 0
            ? `Vencimento em ${format(new Date(nextBillingDates[0]), 'dd/MM/yyyy')}`
            : 'Vencimento indefinido'
          : `Terminando em ${method.last4}`}
      </Typography>
      {method.isDefault && currentDefaultMethod === method.type && (
        <Chip label="Padrão" color="primary" size={isMobile ? 'small' : 'medium'} sx={{ mt: 2 }} />
      )}
    </Box>
  );

  const renderBoletoOption = () => {
    const existingBoleto = paymentMethods.find((m) => m.type === 'boleto');
    const hasTaxInfo = Boolean(userData?.taxId && userData?.taxName && userData?.address);

    return (
      <Box
        key={existingBoleto?.id || 'boleto'}
        sx={
          hasTaxInfo
            ? paymentMethodBoxStyles(selectedPaymentMethodId === (existingBoleto?.id || 'boleto'))
            : {
                ...paymentMethodBoxStyles(false),
                border: `1px dashed ${theme.palette.divider}`,
                '&:hover': {
                  borderColor: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.primary.main, 0.04),
                  transform: 'translateY(-2px)',
                  boxShadow: theme.shadows[4],
                },
              }
        }
        onClick={() => {
          if (existingBoleto) {
            handlePaymentMethodSelect(existingBoleto.id, 'boleto');
          } else if (hasTaxInfo) {
            handlePaymentMethodSelect('boleto', 'boleto');
          } else {
            setOpenBoletoDialog(true);
          }
        }}
      >
        {hasTaxInfo ? (
          <>
            <Typography variant={isMobile ? 'body1' : 'h6'}>
              <Receipt sx={{ mr: 1 }} />
              Boleto Bancário
            </Typography>
            {userData?.taxId && (
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                CPF/CNPJ: {userData.taxId.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
              </Typography>
            )}
            {currentDefaultMethod === 'boleto' && (
              <Chip
                label="Padrão"
                color="primary"
                size={isMobile ? 'small' : 'medium'}
                sx={{ mt: 2 }}
              />
            )}
            {userData?.taxId && (
              <Button
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenBoletoDialog(true);
                }}
                sx={{ mt: 1 }}
              >
                Editar CPF/CNPJ
              </Button>
            )}
          </>
        ) : (
          <>
            <Receipt sx={{ fontSize: 40, color: theme.palette.text.secondary, mb: 1 }} />
            <Button
              variant="text"
              color="primary"
              startIcon={<Add />}
              onClick={(e) => {
                e.stopPropagation();
                setOpenBoletoDialog(true);
              }}
            >
              Boleto Bancário
            </Button>
          </>
        )}
      </Box>
    );
  };

  const handleSetDefaultPaymentMethod = async (
    paymentMethodId: string,
    paymentType: 'card' | 'boleto',
  ) => {
    const title =
      paymentType === 'boleto'
        ? 'Confirmar mudança para boleto'
        : 'Confirmar mudança de cartão padrão';

    const message =
      paymentType === 'boleto'
        ? 'Ao mudar para boleto, suas próximas cobranças serão feitas via boleto bancário. Deseja continuar?'
        : 'Deseja definir este cartão como método de pagamento padrão?';

    setConfirmDialog({
      open: true,
      paymentMethodId,
      paymentType,
      title,
      message,
    });
  };

  const handleConfirmPaymentMethod = async () => {
    const { paymentMethodId, paymentType } = confirmDialog;
    setConfirmDialog((prev) => ({ ...prev, open: false }));

    try {
      if (
        paymentType === 'boleto' &&
        userData?.subscriptionStatus === 'active' &&
        currentPlans.length > 0
      ) {
        const currentPlanId = currentPlans[0].id;
        await switchToBoleto(currentPlanId);
        fetchPaymentInfo();
        showNotification('Método de pagamento alterado para boleto com sucesso', 'success');
      } else if (paymentType === 'card') {
        await setDefaultPaymentMethod(paymentMethodId);
        fetchPaymentInfo();
        showNotification('Método de pagamento padrão atualizado com sucesso', 'success');
      }
    } catch (error: any) {
      showNotification(error.message || 'Erro ao atualizar método de pagamento', 'error');
      trackEvent('payment_error', {
        action: paymentType === 'boleto' ? 'switch_to_boleto' : 'set_default_payment_method',
        error_message: error.message,
      });
    }
  };

  const renderEmptyStateCard = () => (
    <Box
      sx={{
        ...paymentMethodBoxStyles(false),
        border: `1px dashed ${theme.palette.divider}`,
        '&:hover': {
          borderColor: theme.palette.primary.main,
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          transform: 'translateY(-2px)',
          boxShadow: theme.shadows[4],
        },
      }}
      onClick={handleOpenDialog}
    >
      <CreditCard sx={{ fontSize: 40, color: theme.palette.text.secondary, mb: 1 }} />
      <Button variant="text" color="primary" startIcon={<Add />}>
        Adicionar Cartão
      </Button>
    </Box>
  );

  return (
    <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
      <CardHeader title={<Typography variant={isMobile ? 'h6' : 'h5'}>{title}</Typography>} />

      <CardContent>
        {paymentMethods.length > 0 ? (
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            flexWrap={isMobile ? 'nowrap' : 'wrap'}
            gap={2}
          >
            {renderBoletoOption()}
            {paymentMethods.filter((method) => method.type === 'card').map(renderPaymentMethod)}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
            }}
          >
            {renderBoletoOption()}
            {renderEmptyStateCard()}
          </Box>
        )}
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-end',
          p: isMobile ? 1.5 : 2,
          gap: 1,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'stretch',
        }}
      >
        {flow === 'edit' ? (
          <>
            <Button variant="outlined" onClick={handleBack} sx={{ height: '40px' }}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedPaymentMethodId}
              onClick={handleSubmit}
              sx={{ height: '40px' }}
            >
              Continuar
            </Button>
          </>
        ) : paymentMethods.length > 0 ? (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenDialog}
              startIcon={<CreditCard />}
              sx={{ height: '40px' }}
            >
              Adicionar Novo Cartão
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !selectedPaymentMethodId ||
                (selectedPaymentMethodId === 'boleto'
                  ? currentDefaultMethod === 'boleto'
                  : paymentMethods.some(
                      (m) =>
                        m.id === selectedPaymentMethodId &&
                        m.isDefault &&
                        currentDefaultMethod === 'card',
                    ))
              }
              onClick={() => handleSetDefaultPaymentMethod(selectedPaymentMethodId || '', 'card')}
            >
              Tornar método de pagamento padrão
            </Button>
          </>
        ) : null}
      </CardActions>

      <CardDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleAddPaymentMethod}
        stripe={stripeRef.current}
      />

      <TaxInfoDialog
        open={openBoletoDialog}
        onClose={() => setOpenBoletoDialog(false)}
        onSubmit={handleBoletoSubmit}
      />

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}
      >
        <DialogTitle>{confirmDialog.title}</DialogTitle>
        <DialogContent>
          <Typography>{confirmDialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog((prev) => ({ ...prev, open: false }))}>
            Cancelar
          </Button>
          <Button onClick={handleConfirmPaymentMethod} variant="contained" color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default PaymentMethods;
