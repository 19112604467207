import { Functionality, FunctionalityInfo, TrackEvent, TrackEventTypeMap } from '@types';

export const getEventDescription = (event: TrackEvent): string => {
  console.log('event', event);
  switch (event.type) {
    // details
    case 'document_click':
      return `Baixou o edital/documento ${event.metadata.documentIndex + 1} da licitação ${event.metadata.licitacaoId}`;

    // Document related
    case 'document_create':
      return `Criou novo documento ${event.metadata.type}`;
    case 'document_update':
      return `Atualizou documento ${event.metadata.documentId}`;
    case 'document_delete':
      return `Removeu documento ${event.metadata.documentId}`;
    case 'document_child_create':
      return `Adicionou documento filho em ${event.metadata.parentId}`;
    case 'document_child_update':
      return `Atualizou documento filho ${event.metadata.childId}`;
    case 'document_child_delete':
      return `Removeu documento filho ${event.metadata.childId}`;

    // Favorites
    case 'favorite_add':
      return `Adicionou licitação ${event.metadata.licitacaoId} aos favoritos`;
    case 'favorite_remove':
      return `Removeu licitação ${event.metadata.licitacaoId} dos favoritos`;

    // Following
    case 'following_add':
      return `Começou a seguir licitação ${event.metadata.licitacaoId}`;
    case 'following_remove':
      return `Parou de seguir licitação ${event.metadata.licitacaoId}`;
    case 'following_item_add':
      return `Adicionou item ${event.metadata.itemId} ao acompanhamento`;
    case 'following_item_remove':
      return `Removeu item ${event.metadata.itemId} do acompanhamento`;
    case 'following_item_update':
      return `Atualizou item ${event.metadata.itemId} do acompanhamento`;
    case 'following_importance_update':
      return `Atualizou importância do acompanhamento`;
    case 'following_custom_item_add':
      return `Adicionou item personalizado ao acompanhamento`;

    // Kanban
    case 'kanban_update_licitacao':
      return `Atualizou licitação ${event.metadata.licitacaoId} no kanban`;
    case 'kanban_add_licitacoes':
      return `Adicionou licitações à coluna ${event.metadata.columnName}`;
    case 'kanban_remove_licitacao':
      return `Removeu licitação ${event.metadata.licitacaoId} do kanban`;
    case 'kanban_move_licitacao':
      return `Moveu licitação para ${event.metadata.columnName}`;

    // Tags and Annotations
    case 'tag_add':
    case 'tag_remove':
      return `${event.type === 'tag_add' ? 'Adicionou' : 'Removeu'} tag na licitação ${event.metadata.licitacaoId}`;
    case 'annotation_create':
      return `Criou anotação para licitação ${event.metadata.licitacaoId}`;
    case 'annotation_update':
      return `Atualizou anotação ${event.metadata.annotationId}`;
    case 'annotation_remove':
      return `Removeu anotação ${event.metadata.annotationId}`;

    // Search and Bulletins
    case 'search_performed': {
      const filterCount = event.metadata.filters ? Object.keys(event.metadata.filters).length : 0;
      return `Realizou busca com ${filterCount} filtros`;
    }
    case 'saved_search_create':
      return `Salvou busca "${event.metadata.name}"`;
    case 'saved_search_update':
      return `Atualizou busca salva "${event.metadata.name}"`;
    case 'saved_search_remove':
      return `Removeu busca salva ${event.metadata.searchId}`;
    case 'bulletin_create':
      return `Criou boletim "${event.metadata.name}"`;
    case 'bulletin_update':
      return `Atualizou boletim ${event.metadata.bulletinId}`;
    case 'bulletin_remove':
      return `Removeu boletim ${event.metadata.bulletinId}`;

    // Profile and Settings
    case 'profile_update':
      return 'Atualizou perfil';
    case 'profile_delete':
      return `Removeu perfil ${event.metadata.profileId}`;
    case 'preferences_update':
      return `Atualizou preferências: ${event.metadata.changes.join(', ')}`;
    case 'company_logo_upload':
      return 'Atualizou logo da empresa';
    case 'profile_image_upload':
    case 'profile_image_remove':
      return `${event.type === 'profile_image_upload' ? 'Atualizou' : 'Removeu'} foto de perfil`;

    // Payment and Subscription
    case 'payment_intent_created':
      return `Iniciou pagamento do plano ${event.metadata.planId}`;
    case 'payment_method_default_set':
      return 'Definiu método de pagamento padrão';
    case 'subscription_reactivated':
      return 'Reativou assinatura';
    case 'trial_started':
      return `Iniciou período de teste do plano ${event.metadata.planId}`;
    case 'plan_changed':
      return `Alterou para plano ${event.metadata.planId}`;
    case 'subscription_canceled':
      return 'Cancelou assinatura';
    case 'subscription_created':
      return `Criou assinatura do plano ${event.metadata.planId}`;
    case 'subscription_updated':
      return 'Atualizou assinatura';

    // AI Features
    case 'ai_search_generation':
      return 'Gerou busca com IA';
    case 'ai_document_extraction':
      return `Extraiu ${event.metadata.documentsCount} documentos com IA`;
    case 'ai_chat_request':
      return 'Realizou pergunta para IA';

    // Other
    case 'notification_settings_update':
      return 'Atualizou configurações de notificação';
    case 'color_mode_change':
      return `Alterou tema para ${event.metadata.mode}`;
    case 'user_logout':
      return 'Realizou logout';

    default:
      return `Ação: ${event.type}`;
  }
};

export const FUNCTIONALITY_INFO: Record<Functionality, FunctionalityInfo> = {
  [Functionality.DOCUMENTS]: {
    id: Functionality.DOCUMENTS,
    path: '/documentos',
    label: 'Documentos',
  },
  [Functionality.FAVORITES]: {
    id: Functionality.FAVORITES,
    path: '/minhas-licitacoes/favoritas',
    label: 'Favoritos',
  },
  [Functionality.FOLLOWING]: {
    id: Functionality.FOLLOWING,
    path: '/acompanhando',
    label: 'Acompanhamento',
  },
  [Functionality.KANBAN]: {
    id: Functionality.KANBAN,
    path: '/acompanhando/kanban',
    label: 'Kanban',
  },
  [Functionality.TAGS]: {
    id: Functionality.TAGS,
    path: '/licitacao/:id_licitacao',
    label: 'Tags',
  },
  [Functionality.ANNOTATIONS]: {
    id: Functionality.ANNOTATIONS,
    path: '/licitacao/:id_licitacao',
    label: 'Anotações',
  },
  [Functionality.SEARCH]: {
    id: Functionality.SEARCH,
    path: '/busca',
    label: 'Busca',
  },
  [Functionality.BULLETINS]: {
    id: Functionality.BULLETINS,
    path: '/boletins',
    label: 'Boletins',
  },
  [Functionality.PROFILE]: {
    id: Functionality.PROFILE,
    path: '/empresa',
    label: 'Perfil',
  },
  [Functionality.PAYMENTS]: {
    id: Functionality.PAYMENTS,
    path: '/configuracoes/pagamentos',
    label: 'Pagamentos',
  },
  [Functionality.AI]: {
    id: Functionality.AI,
    path: '/busca',
    label: 'IA',
  },
  [Functionality.SETTINGS]: {
    id: Functionality.SETTINGS,
    path: '/configuracoes',
    label: 'Configurações',
  },
};

export const EVENT_TYPE_MAP: TrackEventTypeMap = {
  document_click: { functionality: Functionality.DOCUMENTS, requiresId: true },
  document_create: { functionality: Functionality.DOCUMENTS },
  document_update: { functionality: Functionality.DOCUMENTS },
  document_delete: { functionality: Functionality.DOCUMENTS },
  document_child_create: { functionality: Functionality.DOCUMENTS },
  document_child_update: { functionality: Functionality.DOCUMENTS },
  document_child_delete: { functionality: Functionality.DOCUMENTS },

  favorite_add: { functionality: Functionality.FAVORITES, requiresId: true },
  favorite_remove: { functionality: Functionality.FAVORITES, requiresId: true },

  following_add: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_remove: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_item_add: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_item_remove: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_item_update: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_importance_update: { functionality: Functionality.FOLLOWING, requiresId: true },
  following_custom_item_add: { functionality: Functionality.FOLLOWING, requiresId: true },

  kanban_update_licitacao: { functionality: Functionality.KANBAN, requiresId: true },
  kanban_add_licitacoes: { functionality: Functionality.KANBAN },
  kanban_remove_licitacao: { functionality: Functionality.KANBAN, requiresId: true },
  kanban_move_licitacao: { functionality: Functionality.KANBAN, requiresId: true },

  tag_add: { functionality: Functionality.TAGS, requiresId: true },
  tag_remove: { functionality: Functionality.TAGS, requiresId: true },

  annotation_create: { functionality: Functionality.ANNOTATIONS, requiresId: true },
  annotation_update: { functionality: Functionality.ANNOTATIONS, requiresId: true },
  annotation_remove: { functionality: Functionality.ANNOTATIONS, requiresId: true },

  search_performed: { functionality: Functionality.SEARCH },
  saved_search_create: { functionality: Functionality.SEARCH },
  saved_search_update: { functionality: Functionality.SEARCH },
  saved_search_remove: { functionality: Functionality.SEARCH },

  bulletin_create: { functionality: Functionality.BULLETINS },
  bulletin_update: { functionality: Functionality.BULLETINS },
  bulletin_remove: { functionality: Functionality.BULLETINS },

  profile_create: { functionality: Functionality.PROFILE },
  profile_update: { functionality: Functionality.PROFILE },
  profile_delete: { functionality: Functionality.PROFILE },

  company_logo_upload: { functionality: Functionality.PROFILE },
  profile_image_upload: { functionality: Functionality.PROFILE },
  profile_image_remove: { functionality: Functionality.PROFILE },

  payment_intent_created: { functionality: Functionality.PAYMENTS },
  payment_method_default_set: { functionality: Functionality.PAYMENTS },
  subscription_reactivated: { functionality: Functionality.PAYMENTS },
  trial_started: { functionality: Functionality.PAYMENTS },
  plan_changed: { functionality: Functionality.PAYMENTS },
  subscription_canceled: { functionality: Functionality.PAYMENTS },
  subscription_created: { functionality: Functionality.PAYMENTS },
  subscription_updated: { functionality: Functionality.PAYMENTS },
  boleto_generated: { functionality: Functionality.PAYMENTS },

  ai_search_generation: { functionality: Functionality.AI },
  ai_document_extraction: { functionality: Functionality.AI },
  ai_chat_request: { functionality: Functionality.AI },

  notification_settings_update: { functionality: Functionality.SETTINGS },
  color_mode_change: { functionality: Functionality.SETTINGS },
  user_logout: { functionality: Functionality.SETTINGS },
  preferences_update: { functionality: Functionality.SETTINGS },

  payment_update: { functionality: Functionality.PAYMENTS },
  payment_method_created: { functionality: Functionality.PAYMENTS },

  kanban_update_item: { functionality: Functionality.KANBAN },
  kanban_update_importance: { functionality: Functionality.KANBAN },
  kanban_update_custom_item: { functionality: Functionality.KANBAN },
  kanban_update_notes: { functionality: Functionality.KANBAN },
};

export const getEventLink = (event: TrackEvent): string | null => {
  const eventInfo = EVENT_TYPE_MAP[event.type];
  if (!eventInfo) return null;

  const functionalityInfo = FUNCTIONALITY_INFO[eventInfo.functionality];

  // If event requires licitacaoId and has it, return licitacao path
  if (event.metadata?.licitacaoId) {
    return `/licitacao/${event.metadata.licitacaoId}`;
  }

  // Otherwise return functionality path
  return pathWithId(functionalityInfo.path, event.metadata?.licitacaoId);
};

function pathWithId(path: string, id: string | undefined): string {
  if (!id) return path;
  return path.replace(':id_licitacao', id);
}
