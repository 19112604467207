import { Box, useTheme } from '@mui/material';
import RotatingLogo from './RotatingLogo';

interface SuspenseLoadingProps {
  overlay?: boolean;
}

const SuspenseLoading: React.FC<SuspenseLoadingProps> = ({ overlay = true }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        width: '100%',
        height: '100%',
        minHeight: overlay ? '200px' : '100vh',
        zIndex: theme.zIndex.modal + 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          ...(overlay && {
            backgroundColor: `${theme.palette.background.default}dd`,
            backdropFilter: 'blur(4px)',
          }),
        }}
      >
        <Box
          sx={{
            mb: 2,
            ...(overlay && {
              animation: 'pulse 2s infinite',
              '@keyframes pulse': {
                '0%': { opacity: 0.6 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.6 },
              },
            }),
          }}
        >
          <RotatingLogo color={theme.palette.primary.main} />
        </Box>
      </Box>
    </Box>
  );
};

export default SuspenseLoading;
