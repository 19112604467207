import { Licitacao, Portal, PortalService } from '@types';
import { BanrisulPortalService } from './BanrisulPortal';
import { BB2PortalService } from './BB2Portal';
import { BllBncPortalService } from './BllBncPortal';
import { ComprasbrPortalService } from './ComprasbrPortal';
import { ComprasnetPortalService } from './ComprasnetPortal';
import { ComprasrjPortalService } from './ComprasrjPortal';
import { FiebPortalService } from './FiebPortal';
import { GenericPortalService } from './GenericPortal';
import { LicitaconPortalService } from './LicitaconPortal';
import { LicitanetPortalService } from './LicitanetPortal';
import { LicitardigitalPortalService } from './LicitardigitalPortal';
import { PcpPortalService } from './PcpPortal';
import { PetronectPortalService } from './PetronectPortal';
import { PncpPortalService } from './PncpPortal';
import { ProdespPortalService } from './ProdespPortal';

export class PortalServiceFactory {
  static create(licitacao: Licitacao): PortalService {
    switch (licitacao.id_portal) {
      case Portal.LicitacoesEBancoDoBrasil:
        return new BB2PortalService(licitacao);
      case Portal.ComprasGovernamentais:
        return new ComprasnetPortalService(licitacao);
      case Portal.Banrisul:
        return new BanrisulPortalService(licitacao);
      case Portal.BLLCompras:
      case Portal.BNCCompras:
        return new BllBncPortalService(licitacao);
      case Portal.ComprasBR:
        return new ComprasbrPortalService(licitacao);
      case Portal.LicitaNET:
        return new LicitanetPortalService(licitacao);
      case Portal.LicitarDigital:
        return new LicitardigitalPortalService(licitacao);
      case Portal.PortalDeComprasPublicas:
        return new PcpPortalService(licitacao);
      case Portal.PortalNacionalDeComprasPublicas:
        return new PncpPortalService(licitacao);
      case Portal.PRODESP:
        return new ProdespPortalService(licitacao);
      case Portal.LCON:
        return new LicitaconPortalService(licitacao);
      case Portal.PNET:
        return new PetronectPortalService(licitacao);
      case Portal.COMPRASRJ:
        return new ComprasrjPortalService(licitacao);
      case Portal.FIEB:
        return new FiebPortalService(licitacao);
      default:
        return new GenericPortalService(licitacao);
    }
  }
}
