import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { BulletinWithCounts } from '@types';
import { BulletinPeriods } from './BulletinPeriods';

interface BulletinCardProps {
  bulletin: BulletinWithCounts;
  isSelected: boolean;
  selectedPeriod?: string | null;
  isLoading?: boolean;
  onBulletinClick: (id: string, period?: string) => void;
  onMenuOpen: (event: React.MouseEvent<HTMLElement>, id: string) => void;
}

const BulletinCard: React.FC<BulletinCardProps> = ({
  bulletin,
  isSelected,
  selectedPeriod,
  isLoading = false,
  onBulletinClick,
  onMenuOpen,
}) => {
  const theme = useTheme();

  const handlePeriodClick = (period: string, e: React.MouseEvent) => {
    e.stopPropagation();
    onBulletinClick(bulletin.id, period);
  };

  return (
    <Paper
      className="bulletin-card"
      elevation={3}
      sx={{
        p: 1.5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: isSelected
          ? theme.palette.action.selected
          : theme.palette.background.paper,
        transition: theme.transitions.create(['background-color', 'box-shadow']),
        '&:hover': {
          backgroundColor: isSelected ? theme.palette.action.selected : theme.palette.action.hover,
        },
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => onBulletinClick(bulletin.id)}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: `${theme.palette.background.default}dd`,
            backdropFilter: 'blur(4px)',
            zIndex: 1,
            borderRadius: 1,
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 500,
              color: theme.palette.text.primary,
              mb: 0.5,
            }}
          >
            {bulletin.name}
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 1 }}>
            Total: {bulletin.licitacoesCount} licitações
          </Typography>
        </Box>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onMenuOpen(e, bulletin.id);
          }}
          size="small"
          sx={{
            ml: 1,
            color: theme.palette.text.secondary,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          mt: 'auto',
          width: '100%',
        }}
      >
        <BulletinPeriods
          counts={bulletin.periodCounts}
          selectedPeriod={isSelected ? selectedPeriod : undefined}
          onPeriodClick={handlePeriodClick}
          disabled={false}
        />
      </Box>
    </Paper>
  );
};

export default BulletinCard;
