import { BulletinPeriod, getPeriodLabel, PERIOD_TOOLTIPS } from '@constants/mappings';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { PeriodCounts } from '@types';

interface BulletinPeriodsProps {
  counts: PeriodCounts;
  selectedPeriod?: string | null;
  onPeriodClick: (period: string, e: React.MouseEvent) => void;
  disabled?: boolean;
}

export const BulletinPeriods: React.FC<BulletinPeriodsProps> = ({
  counts,
  selectedPeriod,
  onPeriodClick,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 0.5,
        mt: 1,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {Object.values(BulletinPeriod).map((period) => {
        const isSelected = selectedPeriod === period;
        const count = counts[period];
        const label = getPeriodLabel(period);
        const tooltip = PERIOD_TOOLTIPS[period];

        return (
          <ButtonBase
            key={period}
            onClick={(e) => onPeriodClick(period, e)}
            disabled={disabled || count === 0}
            title={tooltip}
            sx={{
              flex: 1,
              p: 0.75,
              borderRadius: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: isSelected ? 'primary.main' : 'background.paper',
              border: `1px solid ${isSelected ? 'primary.main' : 'divider'}`,
              '&:hover': {
                backgroundColor: isSelected ? 'primary.dark' : 'action.hover',
              },
              transition: theme.transitions.create(['background-color', 'border-color']),
              opacity: count === 0 ? 0.5 : 1,
            }}
          >
            <Typography
              variant="body1"
              color={isSelected ? 'primary.contrastText' : 'text.primary'}
              sx={{ fontWeight: 600, mb: 0.25 }}
            >
              {count}
            </Typography>
            <Typography
              variant="caption"
              color={isSelected ? 'primary.contrastText' : 'text.secondary'}
              sx={{ fontWeight: 500 }}
            >
              {label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Box>
  );
};
