import { Licitacao, PncpLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class BB2PortalService extends BasePortalService {
  name = 'Banco do Brasil';
  logo = '/images/portals/banco-do-brasil.png';
  portal = Portal.LicitacoesEBancoDoBrasil;
  portalData: PncpLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as PncpLicitacao;
  }

  getBaseUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getPortalUrl(): string | null {
    return this.getBaseUrl();
  }

  getApplyUrl(): string | null {
    return this.getBaseUrl();
  }

  getFollowUpUrl(): string | null {
    return this.getBaseUrl();
  }
  getImpugnacaoUrl(): string | null {
    return this.getBaseUrl();
  }

  getLoginUrl(): string | null {
    return `https://authn.bb.com.br/gcs/statics/login/login.novo.bb?tipo=ST_J&urlRetorno=https://licitacoes-e2.bb.com.br/aop-inter-estatico/autorizacaon`;
  }
  getRegisterUrl(): string | null {
    return 'https://licitacoes-e2.bb.com.br/aop-inter-estatico/para-fornecedores';
  }
}
