import MaskedInput from '@components/Input/MaskedInput';
import Loading from '@components/Layout/Loading';
import { useNotification } from '@contexts/NotificationContext';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { AddressElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CNPJ_MASK, CPF_MASK } from '@utils/masks';
import { validateCNPJ, validateCPF } from '@utils/validation';
import React, { useState } from 'react';

if (!process.env.REACT_APP_STRIPE_KEY) {
  throw new Error('REACT_APP_STRIPE_KEY is not set');
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

interface TaxInfoDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const TaxInfoDialog: React.FC<TaxInfoDialogProps> = ({ open, onClose, onSubmit }) => {
  const { userData, updateTaxInfo } = useProfileStore();
  const { showNotification } = useNotification();
  const [taxId, setTaxId] = useState(userData?.taxId || '');
  const [taxName, setTaxName] = useState(userData?.taxName || userData?.name || '');
  const [addressComplete, setAddressComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressValue, setAddressValue] = useState<any | null>(null);
  const [taxIdError, setTaxIdError] = useState<string | null>(null);
  const [documentType, setDocumentType] = useState<'cpf' | 'cnpj'>(
    userData?.taxId?.length === 14 ? 'cnpj' : 'cpf',
  );
  const [isElementsReady, setIsElementsReady] = useState(false);
  const theme = useTheme();

  const validateTaxId = (value: string): boolean => {
    const cleaned = value.replace(/\D/g, '');

    if (documentType === 'cpf') {
      if (!validateCPF(cleaned)) {
        setTaxIdError('CPF inválido');
        return false;
      }
    } else {
      if (!validateCNPJ(cleaned)) {
        setTaxIdError('CNPJ inválido');
        return false;
      }
    }

    setTaxIdError(null);
    return true;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!addressValue) {
        throw new Error('Endereço incompleto');
      }

      const taxIdCleaned = taxId.replace(/\D/g, '');
      if (!validateTaxId(taxIdCleaned)) {
        throw new Error(taxIdError || 'CPF/CNPJ inválido');
      }

      if (taxName.length < 3) {
        throw new Error('Nome/Razão Social inválido');
      }

      await updateTaxInfo(taxIdCleaned, taxName, addressValue.address);
      await onSubmit();
      onClose();
    } catch (error: any) {
      showNotification(error.message || 'Erro ao atualizar dados fiscais', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isNewTaxInfo = taxId !== userData?.taxId || taxName !== userData?.taxName;
  const buttonLabel = isNewTaxInfo ? 'Salvar e Continuar' : 'Continuar';

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ pr: 6 }}>
        Dados Fiscais para Boleto
        <IconButton
          onClick={onClose}
          size="small"
          aria-label="fechar"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Dados do Pagador
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl>
                <RadioGroup
                  row
                  value={documentType}
                  onChange={(e) => {
                    setDocumentType(e.target.value as 'cpf' | 'cnpj');
                    setTaxId(''); // Clear the field when switching
                    setTaxIdError(null);
                  }}
                >
                  <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
                  <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
                </RadioGroup>
              </FormControl>

              <MaskedInput
                label={documentType.toUpperCase()}
                value={taxId}
                onChange={(e) => {
                  setTaxId(e.target.value);
                  if (e.target.value) {
                    validateTaxId(e.target.value);
                  } else {
                    setTaxIdError(null);
                  }
                }}
                mask={documentType === 'cpf' ? CPF_MASK : CNPJ_MASK}
                fullWidth
                required
                disabled={isSubmitting}
                error={!!taxIdError}
                helperText={
                  taxIdError || `Este ${documentType.toUpperCase()} será usado para gerar o boleto`
                }
                onBlur={(e) => {
                  const cleaned = e.target.value.replace(/\D/g, '');
                  validateTaxId(cleaned);
                }}
              />
              <TextField
                label="Nome/Razão Social"
                value={taxName || userData?.name || ''}
                onChange={(e) => setTaxName(e.target.value)}
                fullWidth
                required
                disabled={isSubmitting}
                helperText="Este nome será usado para gerar o boleto"
              />
            </Box>
          </Box>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              Endereço de Cobrança
            </Typography>
            <Box sx={{ position: 'relative', minHeight: 300 }}>
              <Elements
                stripe={stripePromise}
                options={{
                  appearance: {
                    theme: theme.palette.mode === 'dark' ? 'night' : 'stripe',
                    variables: {
                      colorText: theme.palette.text.primary,
                      colorTextSecondary: theme.palette.text.secondary,
                      colorBackground: theme.palette.background.paper,
                      colorPrimary: theme.palette.primary.main,
                    },
                  },
                }}
              >
                {!isElementsReady && <Loading overlay />}
                <AddressElement
                  onReady={() => setIsElementsReady(true)}
                  options={{
                    mode: 'billing',
                    defaultValues: {
                      name: userData?.taxName || userData?.name || '',
                      address: {
                        line1: userData?.address?.street || '',
                        line2: userData?.address?.complement || '',
                        city: userData?.address?.city || '',
                        state: userData?.address?.state || '',
                        postal_code: userData?.address?.postalCode || '',
                        country: 'BR',
                      },
                    },
                    fields: {
                      phone: 'never',
                    },
                    allowedCountries: ['BR'],
                  }}
                  onChange={(event) => {
                    setAddressComplete(event.complete);
                    setAddressValue(event.value);
                  }}
                />
              </Elements>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!taxId || !taxName || !addressComplete || isSubmitting}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaxInfoDialog;
