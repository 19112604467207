import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useState } from 'react';

interface Option {
  value: string | number;
  label: string;
  order?: number;
}

interface MultiSelectProps<T extends Option['value']>
  extends Omit<SelectProps, 'onChange' | 'renderValue'> {
  options: Option[];
  value: T[];
  onChange: (value: T[]) => void;
  label: string;
  renderValue?: (selected: T[], options: Option[]) => React.ReactNode;
  allOptionValue?: string;
  showValueInChip?: boolean; // New prop
}

function MultiSelect<T extends Option['value']>({
  options,
  value,
  onChange,
  label,
  renderValue,
  allOptionValue = '',
  showValueInChip = false, // Default to false
  ...selectProps
}: MultiSelectProps<T>) {
  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedValue = event.target.value as T[];

    let newValue: T[];
    if (allOptionValue !== '') {
      if (selectedValue.includes(allOptionValue as T)) {
        newValue = [allOptionValue as T];
      } else if (value.includes(allOptionValue as T) && selectedValue.length > 0) {
        newValue = selectedValue.filter((v) => v !== allOptionValue);
      } else {
        newValue = selectedValue;
      }
    } else {
      newValue = selectedValue;
    }

    onChange(newValue);
    setOpen(false);
  };

  const defaultRenderValue = (selected: T[], options: Option[]) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {selected.map((value) => {
        const option = options.find((opt) => opt.value === value);
        const chipLabel = showValueInChip ? value : option?.label || value;
        return <Chip key={value} label={chipLabel} />;
      })}
    </Box>
  );

  // Sort options maintaining original order for items without order property
  const sortedOptions = [...options].sort((a, b) => {
    // Ensure we're using the order property for sorting
    const orderA = a.order ?? Number.MAX_SAFE_INTEGER;
    const orderB = b.order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={handleChange}
        renderValue={(selected) =>
          renderValue
            ? renderValue(selected as T[], options)
            : defaultRenderValue(selected as T[], options)
        }
        sx={{
          '& .MuiSelect-select': {
            maxHeight: 120, // Limit visible chips
            overflow: 'auto',
          },
          ...selectProps.sx,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '70vh', // 70% of viewport height
            },
          },
        }}
        {...selectProps}
      >
        {sortedOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;
