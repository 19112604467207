import { useAuth } from '@hooks/useAuth';
import { useProfileStore } from '@store/profileStore';
import React, { useEffect, useRef } from 'react';

declare global {
  interface Window {
    Tawk_API?: any;
    Tawk_LoadStart?: Date;
  }
}

const TawkToChat: React.FC = () => {
  const { userData, company } = useProfileStore();
  const { user } = useAuth();
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    const TAWK_PROPERTY_ID = '67108a3b4304e3196ad3072c';
    const TAWK_WIDGET_ID = '1iac9nufn';
    const TAWK_API_KEY = '2bdc7e6d0f87e911e050669bc13caf3c5cb8a789';

    if (user && userData && !scriptRef.current) {
      window.Tawk_LoadStart = new Date();

      const visitorData = {
        name: userData.name || user.displayName || '',
        email: userData.email || user.email || '',
        company: company?.razaoSocial || '',
        cnpj: company?.cnpj || '',
        userId: user.uid || userData.uid || '',
        photoUrl: user?.photoURL || userData?.photoURL || '',
      };

      const hash = window.Tawk_API?.generateHash(visitorData.email, TAWK_API_KEY);

      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.visitor = {
        name: visitorData.name,
        email: visitorData.email,
        photoUrl: visitorData.photoUrl,
        hash: hash,
      };

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://embed.tawk.to/${TAWK_PROPERTY_ID}/${TAWK_WIDGET_ID}`;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');

      script.onload = () => {
        if (window.Tawk_API) {
          window.Tawk_API.onLoad = () => {
            window.Tawk_API.setAttributes(visitorData, (error: any) => {
              if (error) {
                console.error('Error setting Tawk.to attributes:', error);
              }
            });
          };
        }
      };

      document.head.appendChild(script);
      scriptRef.current = script;
    }

    return () => {
      if (scriptRef.current && scriptRef.current.parentNode) {
        scriptRef.current.parentNode.removeChild(scriptRef.current);
      }
      if (window.Tawk_API) {
        delete window.Tawk_API;
      }
      if ('Tawk_LoadStart' in window) {
        delete window.Tawk_LoadStart;
      }
    };
  }, [userData, user, company]);

  return null;
};

export default TawkToChat;
