import MessageBox from '@components/Common/MessageBox';
import Loading from '@components/Layout/Loading';
import ComparePaymentPlansModal from '@components/Payment/ComparePaymentPlansModal';
import PaymentMethods from '@components/Payment/PaymentMethods';
import PlanSelection from '@components/Payment/PlanSelection';
import { getPlanById, getPlanDurationById, planDurations, plans } from '@constants/plans';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { CheckCircle, Receipt } from '@mui/icons-material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { usePaymentStore } from '@store/paymentStore';
import { useProfileStore } from '@store/profileStore';
import {
  BoletoPaymentResponse,
  DurationId,
  PaymentMethodCard,
  PlanId,
  SubscriptionStatus,
} from '@types';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';

interface SubscriptionFlowProps {
  onClose: () => void;
  onSuccess?: () => void;
  initialStep?: number;
  initialDurationId: DurationId | null;
  initialPlanId: PlanId | null;
  subscriptionId?: string;
}

const SubscriptionFlow: React.FC<SubscriptionFlowProps> = ({
  onClose,
  onSuccess = () => {},
  initialStep = 0,
  initialDurationId,
  initialPlanId,
  subscriptionId: propSubscriptionId,
}) => {
  const theme = useTheme();
  const {
    paymentMethods,
    confirmPaymentAndCreateSubscription,
    switchSubscriptionPlan,
    currentPlans,
    createBoletoSubscription,
    changePlanWithBoleto,
    getBoletoSuccessTitle,
  } = usePaymentStore();
  const { userData } = useProfileStore();

  // Get subscription ID from props or current plans
  const subscriptionId = useMemo(() => {
    if (propSubscriptionId) return propSubscriptionId;
    const activePlan = currentPlans && currentPlans.length > 0 ? currentPlans[0] : null;
    return activePlan?.id || null;
  }, [propSubscriptionId, currentPlans]);

  const [activeStep, setActiveStep] = useState<number>(initialStep ?? 0);
  const [selectedDurationId, setSelectedDurationId] = useState<DurationId>(
    initialDurationId || DurationId.MENSAL,
  );
  const [selectedPlanId, setSelectedPlanId] = useState<PlanId | null>(initialPlanId || null);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [paymentType, setPaymentType] = useState<'card' | 'boleto' | null>(null);
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();
  const [isCompareModalOpen, setIsCompareModalOpen] = useState(false);
  const [boletoUrl, setBoletoUrl] = useState<string | null>(null);
  const [boletoResponse, setBoletoResponse] = useState<BoletoPaymentResponse | null>(null);
  const [dueDate, setDueDate] = useState<string | null>(null);

  useEffect(() => {
    setActiveStep(initialStep ?? 0);
  }, [initialStep]);

  // Reset state when closing
  const handleClose = useCallback(() => {
    setActiveStep(0);
    onClose();
  }, [onClose]);

  const handleSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const steps = subscriptionId
    ? [
        'Selecionar Novo Plano',
        'Selecionar Método de Pagamento',
        'Confirmar Alteração',
        'Finalização',
      ]
    : ['Selecionar Plano', 'Selecionar Método de Pagamento', 'Confirmar Assinatura', 'Conclusão'];

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleNext = (step: number) => {
    setActiveStep(step);
  };

  const handleSetPaymentMethod = async (
    paymentMethodId: string,
    paymentType: 'card' | 'boleto',
  ) => {
    setPaymentMethodId(paymentMethodId);
    setPaymentType(paymentType);
    handleNext(2);
  };

  const handlePlanSelection = async (planId: PlanId, duration: DurationId) => {
    setSelectedPlanId(planId);
    setSelectedDurationId(duration);
    handleNext(1);
  };

  const handleSignUp = async () => {
    if (!selectedPlanId || !selectedDurationId || !paymentMethodId) return;
    let result;
    try {
      if (paymentType === 'boleto') {
        if (subscriptionId) {
          result = await changePlanWithBoleto(selectedPlanId, selectedDurationId);
        } else {
          result = await createBoletoSubscription(selectedPlanId, selectedDurationId);
        }
        setBoletoResponse(result);
        setBoletoUrl(result.boletoUrl);
        setDueDate(result.dueDate);
      } else {
        if (subscriptionId) {
          result = await switchSubscriptionPlan(subscriptionId, selectedPlanId, selectedDurationId);
        } else {
          result = await confirmPaymentAndCreateSubscription(
            selectedPlanId,
            selectedDurationId,
            paymentMethodId,
          );
        }
      }

      handleNext(3);
      trackEvent('subscription_created', {
        plan_id: selectedPlanId,
        duration_id: selectedDurationId,
        payment_type: paymentType,
      });
    } catch (error: any) {
      trackEvent('subscription_error', {
        action: 'create',
        error_message: error.message,
        plan_id: selectedPlanId,
        duration_id: selectedDurationId,
      });
      showNotification(error.message || 'Erro ao processar a operação', 'error');
    }
  };
  const handleStepZero = useCallback(() => {
    const disabledPlans = subscriptionId
      ? [
          {
            planId: userData?.planId as PlanId,
            durationId: userData?.durationId as DurationId,
            message: 'Você já está inscrito neste plano',
          },
        ]
      : [];

    return (
      <PlanSelection
        onChoosePlan={handlePlanSelection}
        defaultPlanId={initialPlanId}
        defaultDurationId={initialDurationId}
        onBack={handleBack}
        onCompare={() => setIsCompareModalOpen(true)}
        disabledPlans={disabledPlans}
      />
    );
  }, [subscriptionId, userData?.planId, userData?.durationId, initialPlanId, initialDurationId]);

  const handleStepOne = useCallback(() => {
    if (!selectedPlanId || !selectedDurationId) {
      handleNext(0);
      return null;
    }
    return (
      <Suspense fallback={<Loading />}>
        <PaymentMethods
          flow="edit"
          onSubmit={handleSetPaymentMethod}
          onBack={handleBack}
          title={
            subscriptionId ? 'Confirmar Método de Pagamento' : 'Selecionar Método de Pagamento'
          }
        />
      </Suspense>
    );
  }, [selectedPlanId, selectedDurationId, subscriptionId]);

  const handleStepTwo = useCallback(() => {
    if (!selectedPlanId || !selectedDurationId || !paymentMethodId) {
      handleNext(0);
      return null;
    }

    const paymentMethod = paymentMethods.find((method) => method.id === paymentMethodId);
    const plan = getPlanById(selectedPlanId);
    const currentPlan = getPlanById(userData?.planId);
    const durationIndex = planDurations.findIndex((d) => d.id === selectedDurationId);

    if (!plan || durationIndex === -1) return null;

    const isUpgrade =
      subscriptionId &&
      currentPlan?.priceInCents &&
      plan.priceInCents[durationIndex] > (currentPlan.priceInCents[durationIndex] || 0);

    const isDowngrade =
      subscriptionId &&
      currentPlan?.priceInCents &&
      plan.priceInCents[durationIndex] < (currentPlan.priceInCents[durationIndex] || 0);

    const duration = getPlanDurationById(selectedDurationId);
    const price = plan?.durationPrices[durationIndex];

    const getActionMessage = () => {
      if (!subscriptionId) return null;

      if (paymentType === 'card') {
        return 'A alteração será feita imediatamente.';
      }

      if (isDowngrade) {
        return 'A alteração será feita imediatamente.';
      }

      return 'A alteração será aplicada assim que recebermos a confirmação do pagamento do boleto.';
    };

    const getDiscountMessage = () => {
      if (!subscriptionId) return null;

      if (paymentType === 'card' && isDowngrade) {
        return 'O valor não utilizado do seu plano atual será automaticamente creditado, e você pagará apenas a diferença para o novo plano.';
      }

      if (paymentType === 'boleto') {
        if (isDowngrade) {
          return 'O valor não utilizado do seu plano atual será creditado na sua próxima fatura.';
        }
        if (isUpgrade) {
          return 'O valor não utilizado do seu plano atual será descontado neste boleto.';
        }
      }

      return null;
    };

    return (
      <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
        <CardHeader
          title={subscriptionId ? 'Confirmar Alteração de Plano' : 'Confirmar Assinatura'}
        />
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {subscriptionId
              ? 'Por favor, confirme os detalhes do novo plano. ' + getActionMessage()
              : 'Por favor, confirme os detalhes do seu plano e forma de pagamento para iniciar a assinatura.'}
          </Typography>

          {getDiscountMessage() && <MessageBox variant="info">{getDiscountMessage()}</MessageBox>}

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Plano Selecionado:
            </Typography>
            <Typography variant="h6">{plan?.name}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Duração:
            </Typography>
            <Typography variant="h6">{duration?.text}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Total:
            </Typography>
            <Typography variant="h6">{price}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="text.secondary">
              Dados do Pagamento:
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant="body1">
                {paymentType === 'boleto'
                  ? 'Boleto'
                  : paymentType === 'card'
                    ? `${(paymentMethod as PaymentMethodCard)?.brand.toUpperCase()} terminando em ${
                        (paymentMethod as PaymentMethodCard)?.last4
                      }`
                    : 'Método de pagamento não selecionado'}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            p: 2,
            gap: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'stretch', sm: 'flex-end' },
            '& > button': {
              width: { xs: '100%', sm: 'auto' },
            },
          }}
        >
          <Button variant="outlined" onClick={handleBack} sx={{ height: '40px' }}>
            Voltar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSignUp}
            sx={{ height: '40px' }}
          >
            {subscriptionId ? 'Confirmar Alteração' : 'Confirmar Assinatura'}
          </Button>
        </CardActions>
      </Card>
    );
  }, [subscriptionId, paymentType, paymentMethodId, selectedPlanId, selectedDurationId, theme]);

  const handleStepThree = useCallback(() => {
    if (paymentType === 'boleto') {
      return (
        <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
          <CardHeader
            title={getBoletoSuccessTitle(boletoResponse?.action)}
            titleTypographyProps={{ variant: 'h6' }}
          />
          <CardContent>
            <MessageBox variant={boletoResponse?.action === 'refund' ? 'info' : 'success'}>
              {boletoResponse?.message || 'Para concluir sua assinatura:'}
            </MessageBox>

            {boletoResponse?.action === 'charge' && (
              <Box sx={{ mt: 2 }}>
                <Box component="ol" sx={{ pl: 2.5, mb: 2 }}>
                  <li>
                    <strong>Imprima</strong> ou copie o código de barras
                  </li>
                  <li>
                    <strong>Pague</strong> via internet banking ou aplicativo do banco
                  </li>
                  <li>
                    <strong>Aguarde</strong> até 3 dias úteis para processamento
                  </li>
                </Box>
                {dueDate && (
                  <Typography variant="body2" color="text.secondary">
                    Vencimento: {new Date(dueDate).toLocaleDateString('pt-BR')}
                  </Typography>
                )}
              </Box>
            )}

            {boletoResponse?.action === 'charge' && boletoUrl && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Receipt />}
                onClick={() => window.open(boletoUrl, '_blank')}
                sx={{ mt: 2 }}
              >
                Visualizar Boleto
              </Button>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
            <Button onClick={handleSuccess} variant="outlined" color="primary">
              Fechar
            </Button>
          </CardActions>
        </Card>
      );
    }

    return (
      <Card sx={{ backgroundColor: theme.palette.background.paper, mb: 4 }}>
        <CardHeader
          title={
            <Box display="flex" alignItems="center" gap={1.5}>
              <CheckCircle color="success" />
              <Typography variant="h6">
                {subscriptionId ? 'Plano Atualizado!' : 'Assinatura Confirmada!'}
              </Typography>
            </Box>
          }
        />
        <CardContent>
          <MessageBox variant="success">
            {subscriptionId
              ? '🎉 Sua alteração de plano foi realizada com sucesso!'
              : '🎉 Bem-vindo(a) ao Licitou!'}
          </MessageBox>

          {selectedPlanId && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" component="div">
                <strong>Plano Contratado:</strong>
                <Box component="div" sx={{ pl: 1.5, mt: 0.5 }}>
                  • {plans.find((p) => p.id === selectedPlanId)?.name}
                  <br />• Duração: {planDurations.find((d) => d.id === selectedDurationId)?.text}
                </Box>
              </Typography>
            </Box>
          )}

          <Typography variant="body2" sx={{ mt: 2 }}>
            {subscriptionId ? (
              'Suas novas configurações de plano já estão ativas.'
            ) : (
              <>
                Você receberá um e-mail de confirmação em <strong>{userData?.email}</strong> com:
                <Box component="ul" sx={{ pl: 2.5, mt: 1 }}>
                  <li>Detalhes da sua assinatura</li>
                  <li>Instruções de acesso</li>
                  <li>Dicas de como usar o Licitou</li>
                </Box>
              </>
            )}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            onClick={handleSuccess}
            variant="contained"
            color="primary"
            startIcon={<CheckCircle />}
          >
            {subscriptionId ? 'Voltar ao Painel' : 'Acessar Plataforma'}
          </Button>
        </CardActions>
      </Card>
    );
  }, [
    subscriptionId,
    paymentType,
    boletoResponse,
    getBoletoSuccessTitle,
    boletoUrl,
    handleSuccess,
    dueDate,
    theme,
    userData?.subscriptionStatus,
  ]);

  return (
    <Paper sx={{ p: 3, mb: 4, backgroundColor: theme.palette.background.paper, borderRadius: 2 }}>
      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep < 2 && selectedPlanId && (
        <Box
          sx={{ mb: 3, px: 2 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body2" color="text.secondary">
            Plano selecionado: {plans.find((p) => p.id === selectedPlanId)?.name} (
            {planDurations.find((d) => d.id === selectedDurationId)?.text})
            {activeStep === 1 && paymentMethodId && (
              <>
                <br />
                {paymentType === 'boleto' ? (
                  'Boleto Bancário'
                ) : (
                  <>
                    {(() => {
                      const method = paymentMethods.find((m) => m.id === paymentMethodId);
                      if (method?.type === 'card') {
                        return (
                          <>
                            {method.brand.toUpperCase()} •••• {method.last4}
                          </>
                        );
                      }
                      return null;
                    })()}
                  </>
                )}
              </>
            )}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CompareArrowsIcon />}
            onClick={() => setIsCompareModalOpen(true)}
            sx={{ ml: 2 }}
          >
            Comparar Planos
          </Button>
        </Box>
      )}

      {activeStep === 0 && handleStepZero()}
      {activeStep === 1 && handleStepOne()}
      {activeStep === 2 && handleStepTwo()}
      {activeStep === 3 && handleStepThree()}

      <ComparePaymentPlansModal
        isOpen={isCompareModalOpen}
        onClose={() => setIsCompareModalOpen(false)}
        plans={plans}
        planDurations={planDurations}
        onChoose={(planId, durationId) => {
          handlePlanSelection(planId as PlanId, durationId as DurationId);
          setIsCompareModalOpen(false);
        }}
        primaryButtonText={
          userData?.subscriptionStatus === SubscriptionStatus.TRIALING
            ? 'Escolher Plano'
            : 'Começar Agora'
        }
        disabledPlans={
          subscriptionId
            ? [
                {
                  planId: userData?.planId as PlanId,
                  durationId: userData?.durationId as DurationId,
                  message: 'Você já está inscrito neste plano',
                },
              ]
            : []
        }
        featuredPlanId={PlanId.PREMIUM}
      />
    </Paper>
  );
};

export default SubscriptionFlow;
