import Loading from '@components/Layout/Loading';
import { CheckCircle as CheckCircleIcon, PictureAsPdf } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { PortalServiceFactory } from '@services/portals/PortalServiceFactory';
import { useFollowingStore } from '@store/followingStore';
import { useTrackStore } from '@store/trackStore';
import { Licitacao, LicitacaoItem, RecommendedLicitacao } from '@types';
import { formatCurrency, formatIsoDate, getComprasNetId } from '@utils';
import React, { useCallback, useMemo, useState } from 'react';

type CombinedLicitacao = Licitacao & RecommendedLicitacao;

const DetailsTabs: React.FC<{ licitacao: CombinedLicitacao }> = ({ licitacao }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { getSelectedItems } = useFollowingStore();
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const { trackDocumentClick } = useTrackStore();

  if (!licitacao) {
    return null;
  }

  const selectedItems = getSelectedItems(licitacao?.id);

  const isItemSelected = (itemId: string) => {
    return selectedItems.some((selected) => selected.id === itemId);
  };

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const filteredItems = useMemo(
    () =>
      licitacao?.items?.filter((item) =>
        item.descricao.toLowerCase().includes(searchTerm.toLowerCase()),
      ) || [],
    [licitacao?.items, searchTerm],
  );

  const TabLabel = useMemo(() => {
    return ({ label, count }: { label: string; count?: number }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {label}
        {count !== undefined && (
          <Box
            component="span"
            sx={{
              marginLeft: '8px',
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              borderRadius: '16px',
              padding: '2px 8px',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              minWidth: '24px',
              textAlign: 'center',
              lineHeight: 1.5,
              boxShadow: `0 0 0 1px ${theme.palette.divider}`,
            }}
          >
            {count}
          </Box>
        )}
      </Box>
    );
  }, [theme]);

  if (!licitacao) {
    return <Loading />;
  }

  const portalService = PortalServiceFactory.create(licitacao);

  const mappedDocuments =
    licitacao.documents?.map((doc) => {
      const url = doc.downloadUrl || doc.url;
      if (url && url.includes('?captcha=1')) {
        const comprasNetId = getComprasNetId(licitacao);
        if (comprasNetId) {
          return {
            ...doc,
            downloadUrl: `${portalService.getLinkBaseUrl()}?url=${encodeURIComponent(url)}&comprasnetId=${comprasNetId}`,
          };
        }
      }
      return doc;
    }) || [];

  const handleDocumentClick = async (licitacaoId: string, documentIndex: number) => {
    trackDocumentClick(licitacaoId, documentIndex);
  };

  const calculateItemValue = useCallback((item: LicitacaoItem, type: 'unit' | 'total') => {
    if (type === 'unit') {
      if (item.valorUnitarioEstimado !== undefined && item.valorUnitarioEstimado !== null) {
        return formatCurrency(item.valorUnitarioEstimado);
      }
      if (item.valorTotal && item.quantidade) {
        return formatCurrency(item.valorTotal / item.quantidade);
      }
      return '-';
    }

    if (type === 'total') {
      if (item.valorTotal !== undefined && item.valorTotal !== null) {
        return formatCurrency(item.valorTotal);
      }
      if (item.valorUnitarioEstimado && item.quantidade) {
        return formatCurrency(item.valorUnitarioEstimado * item.quantidade);
      }
      return '-';
    }
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ backgroundColor: theme.palette.background.paper }}>
          <CardContent>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="licitacao tabs"
              centered
              variant={'fullWidth'}
              sx={{
                borderRadius: '8px 8px 0 0',
                '& .MuiTab-root': {
                  color: theme.palette.text.primary,
                  fontWeight: 'bold',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    color: theme.palette.secondary.main,
                  },
                  fontSize: isMobile ? '0.875rem' : '1rem',
                  minHeight: isMobile ? 48 : 56,
                  padding: isMobile ? '6px 12px' : '12px 16px',
                  width: '50%',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.secondary.main,
                  height: 3,
                },
              }}
            >
              <Tab
                className="items-tab"
                label={<TabLabel label="Itens" count={licitacao?.items?.length || 0} />}
              />
              <Tab
                className="documents-tab"
                label={<TabLabel label="Documentos" count={mappedDocuments.length} />}
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              {tabValue === 0 && (
                <>
                  {licitacao?.items?.length > 0 && (
                    <Box sx={{ mb: 2 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Buscar itens"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: isMobile ? 320 : 440,
                      overflow: 'auto',
                    }}
                  >
                    <Table size={isMobile ? 'small' : 'medium'} stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: isMobile ? '0.75rem' : '0.875rem',
                              whiteSpace: 'nowrap',
                              padding: isMobile ? '8px 4px' : '16px',
                            }}
                          >
                            Item
                          </TableCell>
                          {!isMobile && (
                            <>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  fontSize: isMobile ? '0.75rem' : '0.8125rem',
                                }}
                              >
                                Quantidade
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  fontSize: isMobile ? '0.75rem' : '0.8125rem',
                                }}
                              >
                                Valor Unitário
                              </TableCell>
                            </>
                          )}
                          <TableCell
                            sx={{
                              fontWeight: 'bold',
                              fontSize: isMobile ? '0.75rem' : '0.8125rem',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Valor Total
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredItems.map((item, index) => {
                          const itemId = item.id || `${licitacao.id}-${item.numeroItem || index}`;
                          const selected = isItemSelected(itemId);
                          const itemData = item as LicitacaoItem;
                          return (
                            <TableRow
                              key={index}
                              hover
                              sx={{
                                backgroundColor: selected
                                  ? (theme) => alpha(theme.palette.primary.main, 0.08)
                                  : 'inherit',
                                '&:hover': {
                                  backgroundColor: selected
                                    ? (theme) => alpha(theme.palette.primary.main, 0.12)
                                    : undefined,
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  fontSize: isMobile ? '0.75rem' : '0.8125rem',
                                  padding: isMobile ? '8px 4px' : '16px',
                                }}
                              >
                                <Stack direction="row" spacing={1} alignItems="center">
                                  {selected && (
                                    <CheckCircleIcon color="primary" sx={{ fontSize: 16 }} />
                                  )}
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    sx={{ fontWeight: selected ? 'bold' : 'normal' }}
                                  >
                                    {item.numeroItem}: {item.descricao}
                                    {isMobile && (
                                      <Box
                                        component="span"
                                        sx={{
                                          display: 'block',
                                          color: 'text.secondary',
                                          mt: 1,
                                        }}
                                      >
                                        {`${itemData.quantidade || 0} ${itemData.unidadeMedida || ''}`}
                                        <br />
                                        {calculateItemValue(itemData, 'unit')}
                                      </Box>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              {!isMobile && (
                                <>
                                  <TableCell
                                    sx={{
                                      fontSize: isMobile ? '0.75rem' : '0.8125rem',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >{`${itemData.quantidade || 0} ${itemData.unidadeMedida || ''}`}</TableCell>
                                  <TableCell
                                    sx={{
                                      fontSize: isMobile ? '0.75rem' : '0.8125rem',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {calculateItemValue(itemData, 'unit')}
                                  </TableCell>
                                </>
                              )}
                              <TableCell>{calculateItemValue(itemData, 'total')}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              {tabValue === 1 && (
                <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Nome
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Tipo
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Data
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '0.875rem',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Download
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mappedDocuments.map((doc, index) => (
                        <TableRow key={index} hover>
                          <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.nome}</TableCell>
                          <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.tipo}</TableCell>
                          <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                            {formatIsoDate(doc.dataHora)}
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.8125rem' }}>
                            {doc.downloadUrl || doc.url ? (
                              <Link
                                href={doc.downloadUrl || doc.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => handleDocumentClick(licitacao.id, index)}
                              >
                                <PictureAsPdf color="primary" />
                              </Link>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default React.memo(DetailsTabs);
