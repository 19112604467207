import EmptyStateBox from '@components/Common/EmptyStateBox';
import CurrencyInput from '@components/Input/CurrencyInput';
import {
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { Licitacao, LicitacaoItem } from '@types';
import { formatCurrency } from '@utils';
import React, { useEffect, useState } from 'react';
import { AddCustomItemModal } from './AddCustomItemModal';
import { FollowingItemsAutocomplete } from './FollowingItemsAutocomplete';

interface FollowingItemsListProps {
  licitacao: Licitacao;
}

const calculateItemTotal = (price: number | undefined, quantity: number) => {
  return price ? price * quantity : 0;
};

interface FieldUpdate {
  price?: string;
  marca?: string;
  fabricante?: string;
}

export const FollowingItemsList: React.FC<FollowingItemsListProps> = ({ licitacao }) => {
  const { getSelectedItems, updateItem, addItemToFollowing, removeItemFromFollowing } =
    useFollowingStore();
  const [localFields, setLocalFields] = useState<Record<string, FieldUpdate>>({});
  const [savingStates, setSavingStates] = useState<{
    [itemId: string]: 'saving' | 'success' | 'error' | null;
  }>({});
  const { fetchLicitacaoDetails } = useLicitacoesStore();
  const [isCustomItemModalOpen, setIsCustomItemModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedItems = getSelectedItems(licitacao.id);

  useEffect(() => {
    if (!licitacao.documents && !licitacao.portalData) {
      fetchLicitacaoDetails(licitacao.id);
    }
  }, [licitacao.id, fetchLicitacaoDetails]);

  const handleFieldChange = (itemId: string, field: keyof FieldUpdate, value: string) => {
    setLocalFields((prev) => ({
      ...prev,
      [itemId]: { ...(prev[itemId] || {}), [field]: value },
    }));
    setSavingStates((prev) => ({ ...prev, [itemId]: null }));
  };

  const handleFieldBlur = async (itemId: string, field: keyof FieldUpdate) => {
    const value = localFields[itemId]?.[field];
    if (!value) return;

    setSavingStates((prev) => ({ ...prev, [itemId]: 'saving' }));
    try {
      const updateData = {
        customPrice: field === 'price' ? parseFloat(value) : undefined,
        customMarca: field === 'marca' ? value : undefined,
        customFabricante: field === 'fabricante' ? value : undefined,
      };

      await updateItem(licitacao.id, itemId, updateData);
      setSavingStates((prev) => ({ ...prev, [itemId]: 'success' }));
      setTimeout(() => {
        setSavingStates((prev) => ({ ...prev, [itemId]: null }));
      }, 2000);
    } catch {
      setSavingStates((prev) => ({ ...prev, [itemId]: 'error' }));
      setTimeout(() => {
        setSavingStates((prev) => ({ ...prev, [itemId]: null }));
      }, 3000);
    }
  };

  const grandTotal = selectedItems.reduce((total, item) => {
    const price = localFields[item.id]?.price
      ? parseFloat(localFields[item.id]?.price || '')
      : item.customPrice;
    return total + calculateItemTotal(price, item.quantidade);
  }, 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? 1 : 2,
        width: '100%',
      }}
    >
      {licitacao.items?.length === 0 ? (
        <EmptyStateBox
          title={isMobile ? 'Sem itens cadastrados' : 'Esta licitação não possui itens cadastrados'}
          icon={InfoIcon}
          onClick={() => setIsCustomItemModalOpen(true)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap={isMobile ? 1 : 2}>
            <Typography color="text.secondary" fontSize={isMobile ? '0.813rem' : '0.875rem'}>
              {isMobile
                ? 'Adicione itens manualmente.'
                : 'Não encontramos itens cadastrados para esta licitação no portal.'}
            </Typography>
            <Button
              fullWidth={isMobile}
              size={isMobile ? 'small' : 'medium'}
              startIcon={<AddIcon />}
              onClick={() => setIsCustomItemModalOpen(true)}
              variant="contained"
              color="primary"
            >
              {isMobile ? 'Adicionar Item' : 'Adicionar Item Personalizado'}
            </Button>
          </Box>
        </EmptyStateBox>
      ) : (
        <>
          {/* Search Section */}
          <Box
            sx={{
              display: 'flex',
              gap: isMobile ? 0.5 : 1,
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Box sx={{ flex: 1, width: '100%' }}>
              <FollowingItemsAutocomplete
                items={(licitacao.items as LicitacaoItem[]) || []}
                selectedItems={selectedItems}
                onItemSelect={(item) => addItemToFollowing(licitacao.id, item)}
              />
            </Box>
            {!isMobile && (
              <Typography variant="caption" color="text.primary">
                Ou
              </Typography>
            )}
            <Button
              fullWidth={isMobile}
              size={isMobile ? 'small' : 'medium'}
              startIcon={<AddIcon />}
              onClick={() => setIsCustomItemModalOpen(true)}
              variant="outlined"
            >
              {isMobile ? 'Adicionar Item' : 'Adicionar Item Personalizado'}
            </Button>
          </Box>

          {/* Selected Items Section */}
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: isMobile ? 1 : 2,
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? 0.5 : 0,
              }}
            >
              <Typography variant={isMobile ? 'subtitle2' : 'h6'}>
                Itens ({selectedItems.length})
              </Typography>
              <Typography variant={isMobile ? 'subtitle2' : 'h6'}>
                Total: {formatCurrency(grandTotal)}
              </Typography>
            </Box>

            {selectedItems.length > 0 ? (
              <List>
                {selectedItems.map((item) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      bgcolor: 'background.paper',
                      mb: isMobile ? 0.5 : 1,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      p: isMobile ? 1 : 2,
                      gap: isMobile ? 1 : 2,
                    }}
                  >
                    {/* Delete Button */}
                    <IconButton
                      size="small"
                      onClick={() => removeItemFromFollowing(licitacao.id, item.id)}
                      sx={{
                        position: 'absolute',
                        right: 4,
                        top: 4,
                        color: 'error.main',
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>

                    {/* Item Details */}
                    <Box
                      sx={{
                        width: isMobile ? '100%' : '50%',
                        pr: isMobile ? 4 : 0,
                      }}
                    >
                      <Typography
                        variant={isMobile ? 'subtitle2' : 'subtitle1'}
                        sx={{ fontWeight: 500 }}
                      >
                        Item {item.numeroItem}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: isMobile ? 2 : 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                          fontSize: isMobile ? '0.813rem' : '0.875rem',
                        }}
                      >
                        {item.descricao}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Quantidade: {item.quantidade} {item.unidadeMedida}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Valor unitário estimado: {formatCurrency(item.valorUnitarioEstimado)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Valor total estimado:{' '}
                        {formatCurrency(item.valorUnitarioEstimado * item.quantidade)}
                      </Typography>
                    </Box>

                    {/* Custom Fields */}
                    <Box
                      sx={{
                        width: isMobile ? '100%' : '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: isMobile ? 1 : 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: isMobile ? 1 : 2,
                          flexDirection: isMobile ? 'column' : 'row',
                        }}
                      >
                        <TextField
                          size="small"
                          label="Marca"
                          fullWidth={isMobile}
                          value={localFields[item.id]?.marca || item.customMarca || ''}
                          onChange={(e) => handleFieldChange(item.id, 'marca', e.target.value)}
                          onBlur={() => handleFieldBlur(item.id, 'marca')}
                        />
                        <TextField
                          size="small"
                          label="Fabricante"
                          fullWidth={isMobile}
                          value={localFields[item.id]?.fabricante || item.customFabricante || ''}
                          onChange={(e) => handleFieldChange(item.id, 'fabricante', e.target.value)}
                          onBlur={() => handleFieldBlur(item.id, 'fabricante')}
                        />
                      </Box>

                      {/* Price Input Section */}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <CurrencyInput
                              size="small"
                              label="Seu lance (unit.)"
                              value={
                                localFields[item.id]?.price ?? item.customPrice?.toString() ?? ''
                              }
                              onChange={(value) => handleFieldChange(item.id, 'price', value)}
                              onBlur={() => handleFieldBlur(item.id, 'price')}
                              sx={{ width: '150px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 20 }}>
                              {savingStates[item.id] === 'saving' && <CircularProgress size={16} />}
                              {savingStates[item.id] === 'success' && (
                                <CheckCircleIcon color="success" fontSize="small" />
                              )}
                              {savingStates[item.id] === 'error' && (
                                <ErrorIcon color="error" fontSize="small" />
                              )}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                              flex: 1,
                            }}
                          >
                            <Typography variant="caption" color="text.secondary">
                              Valor total do seu lance
                            </Typography>
                            <Typography variant="subtitle2" color="primary" fontWeight="medium">
                              {formatCurrency(
                                calculateItemTotal(
                                  localFields[item.id]?.price
                                    ? parseFloat(localFields[item.id]?.price || '')
                                    : item.customPrice,
                                  item.quantidade,
                                ),
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <EmptyStateBox
                title={isMobile ? 'Sem itens' : 'Nenhum item selecionado'}
                icon={InfoIcon}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={isMobile ? 1 : 2}
                >
                  <Typography color="text.secondary" fontSize={isMobile ? '0.813rem' : '0.875rem'}>
                    {isMobile
                      ? 'Selecione itens da licitação ou adicione itens personalizados para começar a acompanhar.'
                      : 'Você ainda não selecionou nenhum item para acompanhar nesta licitação.'}
                  </Typography>
                  <Button
                    fullWidth={isMobile}
                    size={isMobile ? 'small' : 'medium'}
                    startIcon={<AddIcon />}
                    onClick={() => setIsCustomItemModalOpen(true)}
                    variant="contained"
                    color="primary"
                  >
                    {isMobile ? 'Adicionar Item' : 'Adicionar Item Personalizado'}
                  </Button>
                </Box>
              </EmptyStateBox>
            )}
          </Box>
        </>
      )}

      <AddCustomItemModal
        open={isCustomItemModalOpen}
        onClose={() => setIsCustomItemModalOpen(false)}
        licitacaoId={licitacao.id}
      />
    </Box>
  );
};
